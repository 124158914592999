import React from 'react';
import "../styles/Spinner.css";

/**Função responsável por retornar o spinner exibido na área do administrador*/
export default function Spinner(){
    return (
        <React.Fragment>
            <div className="background-bg background-admin" />
            <div className="text-center loading-colecione loading-admin">
                <div className="loader-inner">

                    <div className="lds-roller mb-3">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
        
    )
}
