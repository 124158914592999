import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {PrivateRoute} from './auth/PrivateRoute'
import Login from './pages/Login'
import RegisterUser from './pages/RegisterUser'
import DashboardAdmin from './pages/DashboardAdmin'
import DashboardCollector from './pages/DashboardCollector'
import Collection from './pages/Collection'
import RegisterItem from './pages/RegisterItem';
import EditItem from './pages/EditItem';
import ViewItem from './pages/ViewItem';
import EditUser from './pages/EditUser'

/** Significado da propriedade "type"
 * type="1" => apenas usuários do tipo administrador tem acesso
 * type="2" => apenas usuários do tipo colecionador tem acesso
 * type="1,2" => os usuários do tipo administrador e colecionador tem acesso
 */

class App extends Component{
  render(){
    return ( //Define as rotas
        <BrowserRouter>
          <div className = "App">
            <Switch>
              <Route exact path="/" component={Login}/>
              <Route exact path="/cadastrar" component={RegisterUser}/>
              <PrivateRoute type="1" exact path="/admin" component={DashboardAdmin}/>
              <PrivateRoute type="2" exact path="/dashboard" component={DashboardCollector}/>
              <PrivateRoute type="2" exact path="/colecao/:id" component={Collection}/>
              <PrivateRoute type="2" exact path="/colecao/:id/cadastrar" component={RegisterItem}/>
              <PrivateRoute type="2" exact path="/colecao/:idC/:idI/editar" component={EditItem}/>
              <PrivateRoute type="2" exact path="/colecao/:idC/:idI/visualizar" component={ViewItem}/>
              <PrivateRoute type="1,2" exact path="/perfil" component={EditUser}/>
            </Switch>
          </div>
        </BrowserRouter>
    );
  }
}

export default App;

