import React, { Component } from 'react'
import '../styles/DashboardAdmin.css'
import '../styles/ComponentsAdmin.css'
import Spinner from '../components/Spinner'
import CalendarioAdmin from '../components/CalendarioAdmin'
import EmissorAdmin from '../components/EmissorAdmin'
import FormatoAdmin from '../components/FormatoAdmin'
import MaterialAdmin from '../components/MaterialAdmin'
import PadraoMonetarioAdmin from '../components/PadraoMonetarioAdmin'
import PaisAdmin from '../components/PaisAdmin'
import PeriodoAdmin from '../components/PeriodoAdmin'
import TipoColecaoAdmin from '../components/TipoColecaoAdmin'
import {FaBars, FaTimes} from 'react-icons/fa'
import {FiMoreVertical} from 'react-icons/fi'

class DashboardAdmin extends Component {
    
    constructor(){
        super()
        this.state = {
            loading: true,
            navShow: false,
            optionActive: "0"
        }

        this.onChange = this.onChange.bind(this)
        this.toogleClick = this.toogleClick.bind(this)
        this.optionClick = this.optionClick.bind(this)
        this.logout = this.logout.bind(this)
        this.goProfile = this.goProfile.bind(this)
    }

    /**Função executada antes da página ser renderizada*/
    componentDidMount(){
        //console.log("área do administrador")
        this.setState({loading: false})
    }

    /**Armazena um valor em um determinado elemento do state sempre que solicitado*/
    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    /**Armazena a opção do menu que foi selecionada*/
    optionClick(option){
        this.setState({optionActive: option})
    }

    /**Seta a flag utilizada para definir quando a barra de menu deverá ser minimizada ou maximizada*/
    toogleClick(e){
        const aux = !this.state.navShow
        //console.log(aux)
        this.setState({navShow: aux})
    }

    /**Desconecta o usuário do sistema */
    logout(e){
        e.preventDefault()
        //console.log(this)
        localStorage.removeItem('usertoken') //remove o token do localsotrage
        this.props.history.push("/") //redireciona para a área de login
    }

    /**Função responsável por redirecionar o usuário para a área de perfil*/
    goProfile(e){
        e.preventDefault()
        this.props.history.push("/perfil")
    }

    /**Função responsável por retornar o html que será apresentado à página do administrador*/
    render() {

        let mainComponent = null

        //Verifica qual opção do menu foi selecionada para armazenar o componente correspondente
        if (this.state.optionActive === "0"){
            mainComponent = <CalendarioAdmin/>
        }else if(this.state.optionActive === "1"){
            mainComponent = <EmissorAdmin/>
        }else if(this.state.optionActive === "2"){
            mainComponent = <FormatoAdmin/>
        }else if(this.state.optionActive === "3"){
            mainComponent = <MaterialAdmin/>
        }else if(this.state.optionActive === "4"){
            mainComponent = <PadraoMonetarioAdmin/>
        }else if(this.state.optionActive === "5"){
            mainComponent = <PaisAdmin/>
        }else if(this.state.optionActive === "6"){
            mainComponent = <PeriodoAdmin/>
        }else if(this.state.optionActive === "7"){
            mainComponent = <TipoColecaoAdmin/>
        }

        if (this.state.loading){
            return <Spinner/>
		}
        
        const retorno =
        <div className={this.state.navShow ? "dash-admin body-pd" : "dash-admin"}>
            <header className={this.state.navShow ? "header-admin body-pd" : "header-admin"} id="header">
                <div className="header_toggle" onClick={this.toogleClick}>
                    {this.state.navShow ? <FaTimes/> : <FaBars/>} 
                </div>
                <h4 className="header_title">Collecionne</h4>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <button className="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FiMoreVertical size="1.7rem"/>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <button className="dropdown-item" onClick={this.goProfile}>Perfil</button>
                            <button className="dropdown-item" onClick={this.logout}>Sair</button>
                        </div>
                    </li>
                </ul>
            </header>
            <div className={this.state.navShow ? "l-navbar-admin show" : "l-navbar-admin"} id="nav-bar">
                <nav className="nav">
                    <div> 
                        <div className="nav_list"> 
                            <button className={this.state.optionActive==='0' ? "nav_link active" : "nav_link"} 
                            onClick={() => this.optionClick("0")}> 
                                <i className="far fa-calendar-alt"></i>
                                <span className="nav_name">Calendários</span> 
                            </button> 
                            <button className={this.state.optionActive==='1' ? "nav_link active" : "nav_link"} 
                            onClick={() => this.optionClick("1")}> 
                                <i className="fas fa-university"></i>
                                <span className="nav_name">Emissores</span> 
                            </button> 
                            <button className={this.state.optionActive==='2' ? "nav_link active" : "nav_link"} 
                            onClick={() => this.optionClick("2")}> 
                                <i className="fas fa-shapes"></i>
                                <span className="nav_name">Formatos</span> 
                            </button> 
                            <button className={this.state.optionActive==='3' ? "nav_link active" : "nav_link"} 
                            onClick={() => this.optionClick("3")}> 
                                <i className="fas fa-paint-brush"></i>
                                <span className="nav_name">Materiais</span> 
                            </button> 
                            <button className={this.state.optionActive==='4' ? "nav_link active" : "nav_link"} 
                            onClick={() => this.optionClick("4")}> 
                                <i className="fas fa-search-dollar"></i>
                                <span className="nav_name">Padrões Monetário</span> 
                            </button> 
                            <button className={this.state.optionActive==='5' ? "nav_link active" : "nav_link"} 
                            onClick={() => this.optionClick("5")}> 
                                <i className="fas fa-globe-americas"></i>
                                <span className="nav_name">Países</span> 
                            </button> 
                            <button className={this.state.optionActive==='6' ? "nav_link active" : "nav_link"} 
                            onClick={() => this.optionClick("6")}> 
                                <i className="fas fa-hourglass-half"></i>
                                <span className="nav_name">Períodos</span> 
                            </button> 
                            <button className={this.state.optionActive==='7' ? "nav_link active" : "nav_link"} 
                            onClick={() => this.optionClick("7")}> 
                                <i className="fas fa-mail-bulk"></i>
                                <span className="nav_name">Tipos de Coleção</span> 
                            </button> 
                        </div>
                    </div> 
                </nav>
            </div>
            <div className="main-admin">
                {mainComponent}
            </div>
        </div>

    
        return (
            <React.Fragment>
                <div className="background-bg background-admin"></div>
                {retorno}
            </React.Fragment>
            
        ) 
    }
}

export default DashboardAdmin