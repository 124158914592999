import React, { Component } from 'react'
import '../styles/DashboardCollector.css'
import api from '../services/api'
import Spinner from '../components/Spinner'
import HeaderCollector from '../components/HeaderCollector'
import { Link } from 'react-router-dom'

class DashboardCollector extends Component {
    
    constructor(){
        super()
        this.state = {
            loading: true,
        }
    }

    /**Função executada antes da página ser renderizada*/
    componentDidMount(){
        //console.log("área do colecionador")

        //Envia uma requisição ao backend solicitando uma listagem com todos os tipos de coleção visíveis (status = 1)
        api.get('tiposColecao', {
            headers: {
                status: true
            }
        })
        .then( result => {
            //console.log(result.data)
            this.setState({
                tiposColecao: result.data
            })
		})
		.catch(err => {
            //console.log("Erro ao carregar os tipos de coleção.")
		})
        .finally(f => {
            this.setState({loading: false})
        })
    }


    /**Função responsável por retornar o html que será apresentado ao dashboard do colecionador*/
    render() {

        if (this.state.loading){
            return <Spinner/>
		}

        let cardColecao = []

        //Cria um card para cada tipo de coleção presente no array "tiposColecao" do state
        this.state.tiposColecao.forEach(c => {
            cardColecao.push(
                <div key={c.id} className="col-md-3">
                    <Link to={{ pathname: "colecao/"+c.id, state: { name: c.nome} }}> 
                        <div className="card-collection card card-blog">
                            <div className="card-image">
                                <img className="img" alt="" src={process.env.REACT_APP_BACKEND_URL + c.imagem}/>
                            </div>
                            <div className="card-name">
                                <h4 className="category text-name text-center">{c.nome}</h4>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        });
        
        //Inclui todos os cards criados
        const retorno =
        <div className="cards-collections">
            <div className="container">
                <div className="row">
                    {cardColecao}
                </div>
            </div>
        </div>

    
        return (
            <React.Fragment>
                <div className="background-bg background-collector"></div>
                <HeaderCollector history={this.props.history}/>
                {retorno}
            </React.Fragment>
            
        ) 
    }
}

export default DashboardCollector