import React, { Component } from 'react'
import api from '../services/api'
import {BsCircleFill} from 'react-icons/bs'
import {FaPencilAlt, FaTrashAlt, FaTrashRestore} from 'react-icons/fa'
import Spinner from './SpinnerAdmin'
import Alert from 'react-bootstrap/Alert'

class PadraoMonetarioAdmin extends Component {
    
    constructor(){
        super()
        this.state = {
            loading: true,
            padroesMonetario: [],
            nameNewMonetaryStandard: "",
            monetaryStandardSelectedName: "",
            monetaryStandardSelectedId: "",
            showAlert: false,
            alertMsg: "",
            alertVariant: "",
        }

        this.onChange = this.onChange.bind(this)
        this.newMonetaryStandard = this.newMonetaryStandard.bind(this)
        this.loadingMonetaryStandard = this.loadingMonetaryStandard.bind(this)
        this.editMonetaryStandard = this.editMonetaryStandard.bind(this)
        this.removeMonetaryStandard = this.removeMonetaryStandard.bind(this)
        this.restoreMonetaryStandard = this.restoreMonetaryStandard.bind(this)
        this.alertVisible = this.alertVisible.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
    }

    /**Função executada antes da página ser renderizada*/
    componentDidMount(){
        //console.log("padraoMonetario administrador")
        this.loadingMonetaryStandard() //carrega os padrões monetário
    }

    /**Função responsável por carregar todos os padrões monetário cadastrados no banco de dados*/
    loadingMonetaryStandard(){
        /**Função responsável por carregar todos os padrões monetário cadastrados no banco de dados*/
        api.get('padroesMonetario')
        .then( result => {
            //console.log(result.data)
            this.setState({
                padroesMonetario: result.data
            })
		})
		.catch(err => {
            this.setState({alertMsg: "Erro ao carregar os padrões monetário.", alertVariant:"danger"})
		})
        .finally(f => {
            this.setState({loading: false})
        })  
    }

    /**Armazena um valor em um determinado elemento do state sempre que solicitado*/
    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    /**Função que retorna o modal onde o administrador poderá editar o padrão monetário selecionado*/
    modalEdit(){
        return (
            <div className="modal fade" id="modalEdit" tabIndex="-1" role="dialog" aria-labelledby="ModalEdit" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Editar Padrão Monetário</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="monetaryStandard-name" className="col-form-label">Nome do padrão monetário:</label>
                            <input type="text" name="monetaryStandardSelectedName" value={this.state.monetaryStandardSelectedName} 
                            className="form-control" id="recipient-name" onChange={this.onChange}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" onClick={this.editMonetaryStandard} className="btn btn-primary" data-dismiss="modal">Editar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal responsável por solicitar a confirmação da remoção do padrão monetário selecionado*/
    modalRemove(){
        return (
            <div className="modal fade" id="modalRemove" tabIndex="-1" role="dialog" aria-labelledby="ModalRemove" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Remover Padrão Monetário</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 htmlFor="monetaryStandard-name" className="col-form-label">Você realmente deseja remover esse padrão monetário?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Não</button>
                            <button type="button" onClick={this.removeMonetaryStandard} className="btn btn-primary" data-dismiss="modal">Sim</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal responsável por solicitar a confirmação da restauração do padrão monetário selecionado*/
    modalRestore(){
        return (
            <div className="modal fade" id="modalRestore" tabIndex="-1" role="dialog" aria-labelledby="ModalRestore" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Restaurar Padrão Monetário</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 htmlFor="monetaryStandard-name" className="col-form-label">Você deseja restaurar esse padrão monetário?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Não</button>
                            <button type="button" onClick={this.restoreMonetaryStandard} className="btn btn-primary" data-dismiss="modal">Sim</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal onde o administrador poderá cadastrar um novo padrão monetário*/
    modalAdd(){
        return (
            <div className="modal fade" id="modalAdd" tabIndex="-1" role="dialog" aria-labelledby="ModalAdd" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Cadastrar Novo Padrão Monetário</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="monetaryStandard-name" className="col-form-label">Nome do padrão monetário:</label>
                            <input type="text" name="nameNewMonetaryStandard" value={this.state.nameNewMonetaryStandard} 
                            className="form-control" id="recipient-name" onChange={this.onChange}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" onClick={this.newMonetaryStandard} className="btn btn-primary" data-dismiss="modal">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função responsável por cadastrar um novo padrão monetário*/
    newMonetaryStandard(e){
        e.preventDefault()
        //console.log("salvou novo padraoMonetario")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando o cadastro de um novo padrão monetário
        api.post("padraoMonetario", {nome: this.state.nameNewMonetaryStandard})
        .then(result =>{
            if (!result.data.error){
                //console.log("Salvo com sucesso")
                this.loadingMonetaryStandard() //recarrega os padrões monetário
                this.setState({alertMsg: "O padrão monetário foi cadastrado com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao cadastrar o novo padrão monetário.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao cadastrar o novo padrão monetário.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false, nameNewMonetaryStandard: ""})
            this.alertVisible()
        })
    }

    /**Função responsável por atualizar dados de determinado padrão monetário*/
    editMonetaryStandard(e){
        e.preventDefault()
        //console.log("editar padraoMonetario")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando a atualização do padrão monetário
        api.put(`padraoMonetario/${this.state.monetaryStandardSelectedId}`, {nome: this.state.monetaryStandardSelectedName})
        .then(result =>{
            if (!result.data.error){
                //console.log("Editado com sucesso")
                this.loadingMonetaryStandard() //recarrega os padrões monetário
                this.setState({alertMsg: "As alterações foram salvas com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao salvar as alterações.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao salvar as alterações.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false})
            this.alertVisible()
        })
    }

    /**Função responsável por remover (logicamente) um determinado padrão monetário*/
    removeMonetaryStandard(e){
        e.preventDefault()
        //console.log("remover padraoMonetario")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando a remoção (lógica) do padrão monetário
        api.delete(`padraoMonetario/${this.state.monetaryStandardSelectedId}`)
        .then(result =>{
            if (!result.data.error){
                //console.log("Removido com sucesso")
                this.loadingMonetaryStandard() //recarrega os padrões monetário
                this.setState({alertMsg: "O padrão monetário foi removido com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao remover o padrão monetário.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao remover o padrão monetário.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false})
            this.alertVisible()
        })
    }

    /**Função responsável por restaurar um determinado padrão monetário*/
    restoreMonetaryStandard(e){
        e.preventDefault()
        //console.log("restaurar padraoMonetario")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando a restauração do padrão monetário
        api.put(`padraoMonetario/restaurar/${this.state.monetaryStandardSelectedId}`)
        .then(result =>{
            if (!result.data.error){
                //console.log("Restaurado com sucesso", result)
                this.loadingMonetaryStandard() //recarrega os padrões monetário
                this.setState({alertMsg: "O padrão monetário foi restaurado com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao restaurar o padrão monetário.", alertVariant:"danger"})
            }
            
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao restaurar o padrão monetário.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false})
            this.alertVisible()
        })
    }

    /**Exibe uma mensagem ao usuário (erro ou sucesso)*/
    alertVisible(){
        this.setState({showAlert:true})
        
        //determina por quanto tempo a mensagem será exibida
        setTimeout(() => {
            if (this.state.showAlert)
                this.closeAlert()
        }, 5000);
    } 

    /**Fecha a mensagem exibida ao usuário*/
    closeAlert(){
        this.setState({showAlert:false})    
    }

    /**Função que retorna o alert responsável pela mensagem (erro ou sucesso) eventualmente exibida ao usuário */
    alert(){
        return(
            <Alert show={this.state.showAlert} variant={this.state.alertVariant} 
            onClose={this.closeAlert} dismissible>

                <div className="icon">
                    {this.state.alertVariant === "success" ? <i className="fa fa-check"></i> :
                    (this.state.alertVariant === "danger" ? <i className="fa fa-times-circle"></i> : null)}
                </div>

                {this.state.alertVariant === "success" ? <strong>Sucesso! </strong> : 
                (this.state.alertVariant === "danger" ? <strong>Erro! </strong> : "")}
                
                {this.state.alertMsg}
            </Alert>
        )
    }

    /**Função responsável por retornar o html que será apresentado quando a opção padrão monetário for selecionada*/
    render() {

        if (this.state.loading){
            return <Spinner/>
		}

        let rows = []

        //cria as linhas da tabela preenchendo as colunas com as informações dos padrões monetário
        this.state.padroesMonetario.forEach(c => {

            //armazena a data em que o padrão monetário foi atualizado pela última vez, no formato DD/MM/AAAA HH:MM
            let data = new Date(c.atualizadoEm)
            const dia = (data.getDate() < 10) ? '0' + data.getDate().toString() : data.getDate().toString();
            const mes = ((data.getMonth()+1) < 10) ? '0' + (data.getMonth()+1).toString() : (data.getMonth()+1).toString();
            const ano = data.getFullYear()
            const hora = (data.getHours() < 10) ? '0' + data.getHours().toString() : data.getHours().toString();
            const minutos = (data.getMinutes() < 10) ? '0' + data.getMinutes().toString() : data.getMinutes().toString();
            const dataFormat = dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos

            //insere cada linha em um array chamado "rows"
            rows.push(
                <tr key={c.id}>
                    <td>{c.id}</td>
                    <td>{c.nome}</td>
                    <td className="comp-admin-status">
                        {c.status === 1 ? 
                            <React.Fragment>
                                <BsCircleFill size="7px" color="green" className="mr-2"/> Ativo
                            </React.Fragment> : 
                            <React.Fragment>
                                <BsCircleFill size="7px" color="red" className="mr-2"/> Excluído
                            </React.Fragment>
                        }
                        </td>
                    <td>{dataFormat}</td>
                    <td>
                        {c.status === 1 ? 
                            <React.Fragment>
                                <button title="Editar" className="admin-actions" data-toggle="modal" 
                                data-target="#modalEdit" data-backdrop="static" data-keyboard="false"
                                onClick={() => this.setState({monetaryStandardSelectedName: c.nome, monetaryStandardSelectedId: c.id})}>
                                    <FaPencilAlt/>
                                </button>
                                <button title="Remover" className="admin-actions" data-toggle="modal" 
                                data-target="#modalRemove" data-backdrop="static" data-keyboard="false"
                                onClick={() => this.setState({monetaryStandardSelectedName: c.nome, monetaryStandardSelectedId: c.id})}>
                                    <FaTrashAlt/>
                                </button>
                            </React.Fragment> :
                            <button title="Restaurar" className="admin-actions" data-toggle="modal" 
                            data-target="#modalRestore" data-backdrop="static" data-keyboard="false"
                            onClick={() => this.setState({monetaryStandardSelectedName: c.nome, monetaryStandardSelectedId: c.id})}>
                                <FaTrashRestore/>
                            </button>
                        }
                    </td>
                </tr>
            )
        });
        
        //cria a tabela e inclui as linhas geradas e armazenadas no array chamado "rows"
        const retorno =
        <div className="component-admin">
            <div className="container-fluid p-0">
                <div className="mb-4">
                    <h5 className="header-title"> Padrões Monetário</h5>
                    <hr/>
                </div>

                <div className="">
                    <button className="comp-admin-add mb-3" data-toggle="modal" data-target="#modalAdd"
                    data-backdrop="static" data-keyboard="false">
                        <i className="fas fa-plus"></i> Adicionar
                    </button>
                    <div className="pull-right"> </div>
                </div>
                <div>
                    <div className="table-responsive">
                        <table className="table table-hover table-borderless">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Status</th>
                                    <th>Data de Modificação</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
        
                </div>
            </div>
            
        </div>

        return (
            <React.Fragment>
                {this.alert()}
                {retorno}
                {this.modalAdd()}
                {this.modalEdit()}
                {this.modalRemove()}
                {this.modalRestore()}
            </React.Fragment>
            
        ) 
    }
}

export default PadraoMonetarioAdmin