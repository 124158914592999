import React, { Component } from 'react'
import {FaTrashAlt} from 'react-icons/fa'

class ItemEtapa2 extends Component {

	state={
		imgSelected: ""
	}
    
    componentDidMount(){
        //console.log("Etapa 2", this.state)
    }

	/**Retorna um modal onde a imagem selecionada será maximizada preenchendo todo o espaço do modal aberto */
	modalImage(){
        return (
            <div className="modal fade" id="modalImage" tabIndex="-1" role="dialog" aria-labelledby="ModalImage" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
						<div className="modalImage-close">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<img src={this.state.imgSelected} alt="Imagem do item" />                        
                    </div>
                </div>
            </div>
        )
    }

	/**Armazena o src da imagem selecionada para ser exibida no modal */
	setImgSelected(src){
		this.setState({imgSelected: src})
	}

	/**Função responsável por retornar o html que será apresentado na etapa 2 (face do anverso)
	 * da área de cadastro ou da área de edição de item*/
    render() {

		//armazena o "this" do componente pai (RegisterItem ou EditItem) na constante "thisSuper"
        const thisSuper = this.props.this //necessário para acessar as funções e propriedades do componente pai

        let imgsAnverso = []

		//cria as linhas da tabela preenchendo as colunas com: o index da imagem, a imagem e um botão para remoção (lixeira)
		thisSuper.state.imgsAnverso.forEach((i, index) => {
			//verifica se o src da imagem não está vazio e se o status é diferente de "delete"
			if(i.img !== "" && i.status !== "delete"){
				imgsAnverso.push(
				<tr key={index}>
					<td>{index+1}</td>
					<td><img src={i.img} alt="Imagem do item" 
						data-toggle="modal" data-target="#modalImage" 
						onClick={() => this.setImgSelected(i.img)}/>
					</td>
					<td>
						<button title="Remover" className="admin-actions" 
						onClick={(e) => thisSuper.removeImage(e,index)}>
							<FaTrashAlt/>
                        </button>
					</td>
				</tr>)
			}
		});

		return (
			<fieldset>
				<section className="section-head text-center">
					<strong>Face Anverso</strong>
				</section>
				<section className="section-body text-left">
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<div className="form-group">
									<label className="lb-title">Gravador</label>
									<input type="text" className={"form-control"} placeholder="Gravador" 
									name="gravadorAnverso" value={thisSuper.state.gravadorAnverso} onChange={(e) => thisSuper.onChange(e, "face1")}/>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<div className="form-group">
									<label className="lb-title">Legenda</label>
									<input type="text" className={"form-control"} placeholder="Legenda" 
									name="legendaAnverso" value={thisSuper.state.legendaAnverso} onChange={(e) => thisSuper.onChange(e, "face1")}/>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<label className="lb-title">Descrição</label>
							<textarea className="form-control" placeholder="Descrição" 
							name="descricaoAnverso" value={thisSuper.state.descricaoAnverso} onChange={(e) => thisSuper.onChange(e, "face1")}/>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="btn-item-img">
								<label className="lb-title">Imagens</label>
								<input id="item-fileupload" type="file" accept="image/*" className="d-none" onChange={(e) => thisSuper.loadImage(e, "2")} ref="file"/>
								<button type="button" className="btn btn-info" onClick={thisSuper.wrapImage}>Nova imagem</button>
							</div>	
							<div className="mt-2">
								<div className="table-responsive">
									<table className="table table-hover table-item-img">
										<thead>
											<tr>
												<th>#</th>
												<th>Imagem</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{imgsAnverso}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 text-center mt-4 row-action-buttons">
							<input type="button" name="previous" className="previous action-button btn btn-secondary" value="Voltar" 
							onClick={thisSuper.previousEtapa}/>
							<input type="button" name="next" className="next action-button btn btn-primary" 
							onClick={thisSuper.nextEtapa} value="Próximo" />
						</div>
					</div>			
				</section>
				{this.modalImage()}
			</fieldset>
		)
    }
}

export default ItemEtapa2