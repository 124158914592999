import React, { Component } from 'react'
import api from '../services/api'
import {BsCircleFill} from 'react-icons/bs'
import {FaPencilAlt, FaTrashAlt, FaTrashRestore} from 'react-icons/fa'
import Spinner from './SpinnerAdmin'
import Alert from 'react-bootstrap/Alert'

class MaterialAdmin extends Component {
    
    constructor(){
        super()
        this.state = {
            loading: true,
            materiais: [],
            nameNewMaterial: "",
            materialSelectedName: "",
            materialSelectedId: "",
            showAlert: false,
            alertMsg: "",
            alertVariant: "",
        }

        this.onChange = this.onChange.bind(this)
        this.newMaterial = this.newMaterial.bind(this)
        this.loadingMaterial = this.loadingMaterial.bind(this)
        this.editMaterial = this.editMaterial.bind(this)
        this.removeMaterial = this.removeMaterial.bind(this)
        this.restoreMaterial = this.restoreMaterial.bind(this)
        this.alertVisible = this.alertVisible.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
    }

    /**Função executada antes da página ser renderizada*/
    componentDidMount(){
        //console.log("material administrador")
        this.loadingMaterial() //carrega os materiais
    }

    /**Função responsável por carregar todos os materiais cadastrados no banco de dados*/
    loadingMaterial(){
        //envia uma requisição ao backend solicitando uma listagem com todos materiais cadastrados
        api.get('materiais')
        .then( result => {
            //console.log(result.data)
            this.setState({
                materiais: result.data
            })
		})
		.catch(err => {
            this.setState({alertMsg: "Erro ao carregar os materiais.", alertVariant:"danger"})
		})
        .finally(f => {
            this.setState({loading: false})
        })  
    }

    /**Armazena um valor em um determinado elemento do state sempre que solicitado*/
    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    /**Função que retorna o modal onde o administrador poderá editar o materiais selecionado*/
    modalEdit(){
        return (
            <div className="modal fade" id="modalEdit" tabIndex="-1" role="dialog" aria-labelledby="ModalEdit" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Editar Material</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="material-name" className="col-form-label">Nome do material:</label>
                            <input type="text" name="materialSelectedName" value={this.state.materialSelectedName} 
                            className="form-control" id="recipient-name" onChange={this.onChange}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" onClick={this.editMaterial} className="btn btn-primary" data-dismiss="modal">Editar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal responsável por solicitar a confirmação da remoção do materiais selecionado*/
    modalRemove(){
        return (
            <div className="modal fade" id="modalRemove" tabIndex="-1" role="dialog" aria-labelledby="ModalRemove" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Remover Material</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 htmlFor="material-name" className="col-form-label">Você realmente deseja remover esse material?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Não</button>
                            <button type="button" onClick={this.removeMaterial} className="btn btn-primary" data-dismiss="modal">Sim</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal responsável por solicitar a confirmação da restauração do materiais selecionado*/
    modalRestore(){
        return (
            <div className="modal fade" id="modalRestore" tabIndex="-1" role="dialog" aria-labelledby="ModalRestore" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Restaurar Material</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 htmlFor="material-name" className="col-form-label">Você deseja restaurar esse material?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Não</button>
                            <button type="button" onClick={this.restoreMaterial} className="btn btn-primary" data-dismiss="modal">Sim</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal onde o administrador poderá cadastrar um novo materiais*/
    modalAdd(){
        return (
            <div className="modal fade" id="modalAdd" tabIndex="-1" role="dialog" aria-labelledby="ModalAdd" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Cadastrar Novo Material</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="material-name" className="col-form-label">Nome do material:</label>
                            <input type="text" name="nameNewMaterial" value={this.state.nameNewMaterial} 
                            className="form-control" id="recipient-name" onChange={this.onChange}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" onClick={this.newMaterial} className="btn btn-primary" data-dismiss="modal">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função responsável por cadastrar um novo materiais*/
    newMaterial(e){
        e.preventDefault()
        //console.log("salvou novo material")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando o cadastro de um novo materiais
        api.post("material", {nome: this.state.nameNewMaterial})
        .then(result =>{
            if (!result.data.error){
                //console.log("Salvo com sucesso")
                this.loadingMaterial() //recarrega os materiais
                this.setState({alertMsg: "O material foi cadastrado com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao cadastrar o novo material.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao cadastrar o novo material.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false, nameNewMaterial: ""})
            this.alertVisible()
        })
    }

    /**Função responsável por atualizar dados de determinado materiais*/
    editMaterial(e){
        e.preventDefault()
        //console.log("editar material")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando a atualização do materiais
        api.put(`material/${this.state.materialSelectedId}`, {nome: this.state.materialSelectedName})
        .then(result =>{
            if (!result.data.error){
                //console.log("Editado com sucesso")
                this.loadingMaterial() //recarrega os materiais
                this.setState({alertMsg: "As alterações foram salvas com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao salvar as alterações.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao salvar as alterações.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false})
            this.alertVisible()
        })
    }

    /**Função responsável por remover (logicamente) um determinado materiais*/
    removeMaterial(e){
        e.preventDefault()
        //console.log("remover material")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando a remoção (lógica) do materiais
        api.delete(`material/${this.state.materialSelectedId}`)
        .then(result =>{
            if (!result.data.error){
                //console.log("Removido com sucesso")
                this.loadingMaterial() //recarrega os materiais
                this.setState({alertMsg: "O material foi removido com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao remover o material.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao remover o material.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false})
            this.alertVisible()
        })
    }

    /**Função responsável por restaurar um determinado materiais*/
    restoreMaterial(e){
        e.preventDefault()
        //console.log("restaurar material")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando a restauração do materiais
        api.put(`material/restaurar/${this.state.materialSelectedId}`)
        .then(result =>{
            if (!result.data.error){
                //console.log("Restaurado com sucesso", result)
                this.loadingMaterial() //recarrega os materiais
                this.setState({alertMsg: "O material foi restaurado com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao restaurar o material.", alertVariant:"danger"})
            }
            
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao restaurar o material.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false})
            this.alertVisible()
        })
    }

    /**Exibe uma mensagem ao usuário (erro ou sucesso)*/
    alertVisible(){
        this.setState({showAlert:true})
        
        //determina por quanto tempo a mensagem será exibida
        setTimeout(() => {
            if (this.state.showAlert)
                this.closeAlert()
        }, 5000);
    } 

    /**Fecha a mensagem exibida ao usuário*/
    closeAlert(){
        this.setState({showAlert:false})    
    }

    /**Função que retorna o alert responsável pela mensagem (erro ou sucesso) eventualmente exibida ao usuário */
    alert(){
        return(
            <Alert show={this.state.showAlert} variant={this.state.alertVariant} 
            onClose={this.closeAlert} dismissible>

                <div className="icon">
                    {this.state.alertVariant === "success" ? <i className="fa fa-check"></i> :
                    (this.state.alertVariant === "danger" ? <i className="fa fa-times-circle"></i> : null)}
                </div>

                {this.state.alertVariant === "success" ? <strong>Sucesso! </strong> : 
                (this.state.alertVariant === "danger" ? <strong>Erro! </strong> : "")}
                
                {this.state.alertMsg}
            </Alert>
        )
    }

    /**Função responsável por retornar o html que será apresentado quando a opção materiais for selecionada*/
    render() {

        if (this.state.loading){
            return <Spinner/>
		}

        let rows = []

        //cria as linhas da tabela preenchendo as colunas com as informações dos materiais
        this.state.materiais.forEach(c => {

            //armazena a data em que o materiais foi atualizado pela última vez, no formato DD/MM/AAAA HH:MM
            let data = new Date(c.atualizadoEm)
            const dia = (data.getDate() < 10) ? '0' + data.getDate().toString() : data.getDate().toString();
            const mes = ((data.getMonth()+1) < 10) ? '0' + (data.getMonth()+1).toString() : (data.getMonth()+1).toString();
            const ano = data.getFullYear()
            const hora = (data.getHours() < 10) ? '0' + data.getHours().toString() : data.getHours().toString();
            const minutos = (data.getMinutes() < 10) ? '0' + data.getMinutes().toString() : data.getMinutes().toString();
            const dataFormat = dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos

            //insere cada linha em um array chamado "rows"
            rows.push(
                <tr key={c.id}>
                    <td>{c.id}</td>
                    <td>{c.nome}</td>
                    <td className="comp-admin-status">
                        {c.status === 1 ? 
                            <React.Fragment>
                                <BsCircleFill size="7px" color="green" className="mr-2"/> Ativo
                            </React.Fragment> : 
                            <React.Fragment>
                                <BsCircleFill size="7px" color="red" className="mr-2"/> Excluído
                            </React.Fragment>
                        }
                        </td>
                    <td>{dataFormat}</td>
                    <td>
                        {c.status === 1 ? 
                            <React.Fragment>
                                <button title="Editar" className="admin-actions" data-toggle="modal" 
                                data-target="#modalEdit" data-backdrop="static" data-keyboard="false"
                                onClick={() => this.setState({materialSelectedName: c.nome, materialSelectedId: c.id})}>
                                    <FaPencilAlt/>
                                </button>
                                <button title="Remover" className="admin-actions" data-toggle="modal" 
                                data-target="#modalRemove" data-backdrop="static" data-keyboard="false"
                                onClick={() => this.setState({materialSelectedName: c.nome, materialSelectedId: c.id})}>
                                    <FaTrashAlt/>
                                </button>
                            </React.Fragment> :
                            <button title="Restaurar" className="admin-actions" data-toggle="modal" 
                            data-target="#modalRestore" data-backdrop="static" data-keyboard="false"
                            onClick={() => this.setState({materialSelectedName: c.nome, materialSelectedId: c.id})}>
                                <FaTrashRestore/>
                            </button>
                        }
                    </td>
                </tr>
            )
        });
        
        //cria a tabela e inclui as linhas geradas e armazenadas no array chamado "rows"
        const retorno =
        <div className="component-admin">
            <div className="container-fluid p-0">
                <div className="mb-4">
                    <h5 className="header-title"> Materiais</h5>
                    <hr/>
                </div>

                <div className="">
                    <button className="comp-admin-add mb-3" data-toggle="modal" data-target="#modalAdd"
                    data-backdrop="static" data-keyboard="false">
                        <i className="fas fa-plus"></i> Adicionar
                    </button>
                    <div className="pull-right"> </div>
                </div>
                <div>
                    <div className="table-responsive">
                        <table className="table table-hover table-borderless">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Status</th>
                                    <th>Data de Modificação</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
        
                </div>
            </div>
            
        </div>

        return (
            <React.Fragment>
                {this.alert()}
                {retorno}
                {this.modalAdd()}
                {this.modalEdit()}
                {this.modalRemove()}
                {this.modalRestore()}
            </React.Fragment>
            
        ) 
    }
}

export default MaterialAdmin