import React, { Component } from 'react'
import '../styles/RegisterEditItem.css'
import {Redirect} from 'react-router-dom'
import api from '../services/api'
import Spinner from '../components/Spinner'
import HeaderCollector from '../components/HeaderCollector'
import ItemEtapa1 from '../components/ItemEtapa1'
import ItemEtapa2 from '../components/ItemEtapa2'
import ItemEtapa3 from '../components/ItemEtapa3'
import Modal from 'react-bootstrap/Modal'

class EditItem extends Component {
	
    constructor(){
        super()
        this.state = {
			loading: true,
			itemId: 0,
			typeCollection: "",
			funcItem: 0,
			funcFace: 0,
			funcImg: 0,
			erroItem: false,
			erroFace: false,
			erroImg: false,
			etapa: 1,
			pais: '0',
			paises: [],
			padraoM: '0',
			padroesM: [],
			emissor: '',
			emissores: [],
			periodo: '',
			periodos: [],
			formato: '',
			formatos: [],
			material: '',
			materiais: [],
			calendario: '',
			calendarios: [],
			periodoEmissao: '',
			valor: '',
			valorFace: '',
			altura: '',
			largura: '',
			espessura: '',
			peso: '',
			foraCirculacao: false,
			comentario: '',
			gravadorAnverso: '',
			legendaAnverso: '',
			descricaoAnverso: '',
			gravadorVerso: '',
			legendaVerso: '',
			descricaoVerso: '',
			imgsAnverso: [],
			imgsVerso: [],
			modificouItem: false,
			modificouFaceAnverso: false,
			modificouFaceVerso: false,
			modificou: false,
			showAlertImage: false,
            msgAlertImage: ""
        }
		
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
		this.onChangeCheckBox = this.onChangeCheckBox.bind(this)
		this.nextEtapa = this.nextEtapa.bind(this)
		this.previousEtapa = this.previousEtapa.bind(this)
		this.loadImage = this.loadImage.bind(this)
        this.wrapImage = this.wrapImage.bind(this)
		this.removeImage = this.removeImage.bind(this)
		this.updateItem = this.updateItem.bind(this)
		this.updateFaceAnverso = this.updateFaceAnverso.bind(this)
		this.updateFaceVerso = this.updateFaceVerso.bind(this)
		this.updateImgAnverso = this.updateImgAnverso.bind(this)
		this.updateImgVerso = this.updateImgVerso.bind(this)
    }
    
	/**Função executada antes da página ser renderizada*/
    componentDidMount(){
		//envia uma requisição ao backend solicitando os dados do item
		api.get(`item/${this.props.match.params.idI}`)
		.then( result => {
			if (!result.data.error){
				let dataPeriodoEmissao = ""

				if (result.data[0].periodoEmissao !== null){ //se houver período de emissão
					//armazena o período de emissão no formato AAAA-MM-DD
					let data = new Date((result.data[0].periodoEmissao).replace(/-/g, '/').replace(/T.+/,''))
					const dia = (data.getDate() < 10) ? '0' + data.getDate().toString() : data.getDate().toString();
					const mes = ((data.getMonth()+1) < 10) ? '0' + (data.getMonth()+1).toString() : (data.getMonth()+1).toString();
					const ano = data.getFullYear()
					dataPeriodoEmissao = ano + "-" + mes + "-" + dia
				}
				
				this.setState({
					itemId: result.data[0].id,
					pais: result.data[0].pais_id,
					emissor: result.data[0].emissor_id === null ? "" : result.data[0].emissor_id,
					periodo: result.data[0].periodo_id === null ? "" : result.data[0].periodo_id,
					formato: result.data[0].formato_id === null ? "" : result.data[0].formato_id,
					padraoM: result.data[0].padrao_monetario_id,
					calendario: result.data[0].calendario_id === null ? "" : result.data[0].calendario_id,
					material: result.data[0].material_id === null ? "" : result.data[0].material_id,
					periodoEmissao: dataPeriodoEmissao,
					valor: result.data[0].valor === null ? "" : result.data[0].valor,
					valorFace: result.data[0].valorFace === null ? "" : result.data[0].valorFace,
					foraCirculacao: !!result.data[0].foraCirculacao,
					peso: result.data[0].peso === null ? "" : result.data[0].peso,
					largura: result.data[0].largura === null ? "" : result.data[0].largura,
					altura: result.data[0].altura === null ? "" : result.data[0].altura,
					espessura: result.data[0].espessura === null ? "" : result.data[0].espessura,
					comentario: result.data[0].comentario === null ? "" : result.data[0].comentario,
					typeCollection: result.data[0].tipo_colecao_nome
				})
			}else{
				//console.log("Erro ao carregar dados do item")
			}
			//console.log(result)
		})
		.catch(err => {
			//console.log("Erro ao carregar dados do item")
		})
		.finally(() => {
			this.setState({loading: false});
		})

		//envia uma requisição ao backend solicitando os dados da face do anverso do item
		api.get(`face/${this.props.match.params.idI}/1`)
		.then( face1 => {
			//console.log("face1", face1)
			this.setState({loading:true});

			if(!face1.data.error) {
				this.setState({
					gravadorAnverso: face1.data[0].gravador === null ? "" : face1.data[0].gravador,
					legendaAnverso: face1.data[0].legenda === null ? "" : face1.data[0].legenda,
					descricaoAnverso: face1.data[0].descricao === null ? "" : face1.data[0].descricao
				})

				//envia uma requisição ao backend solicitando uma listagem com as imagens da face do anverso
				api.get("imagensFace", {
					headers: {
						item: face1.data[0].item_id,
						posicao: face1.data[0].posicao
					}
				}).then(resImg =>{
					//console.log(resImg)
					if (!resImg.data.error){
						const aux = []
						resImg.data.forEach(i => {
							aux.push({
								img: process.env.REACT_APP_BACKEND_URL + i.imagem,
								status: "before",
								id: i.id
							})
						});
						this.setState({imgsAnverso: aux})
					}else{
						//console.log("Não foi possível carregar a imagem da face do anverso. Por favor, entre em contato com o administrador do sistema.")
					}
				})
				.catch(e => {
					//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
				})
				.finally( () => {
					this.setState({loading:false});
				})
			} else {
				this.setState({loading:false});
				//console.log("Não foi possível carregar a face do anverso. Por favor, entre em contato com o administrador do sistema.")
			}
		})
		.catch(error => {
			this.setState({loading:false});
			//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
		})

		//envia uma requisição ao backend solicitando os dados da face do verso do item
		api.get(`face/${this.props.match.params.idI}/2`)
		.then( face2 => {
			//console.log("face2", face2)
			this.setState({loading:true});

			if(!face2.data.error) {
				this.setState({
					gravadorVerso: face2.data[0].gravador === null ? "" : face2.data[0].gravador,
					legendaVerso: face2.data[0].legenda === null ? "" : face2.data[0].legenda,
					descricaoVerso: face2.data[0].descricao === null ? "" : face2.data[0].descricao
				})

				//envia uma requisição ao backend solicitando uma listagem com as imagens da face do verso
				api.get("imagensFace", {
					headers: {
						item: face2.data[0].item_id,
						posicao: face2.data[0].posicao
					}
				}).then(resImg =>{
					//console.log(resImg)
					if (!resImg.data.error){
						const aux = []
						resImg.data.forEach(i => {
							aux.push({
								img: process.env.REACT_APP_BACKEND_URL + i.imagem,
								status: "before",
								id: i.id
							})
						});
						this.setState({imgsVerso: aux})
					}else{
						//console.log("Não foi possível carregar a imagem da face do verso. Por favor, entre em contato com o administrador do sistema.")
					}
				})
				.catch(e => {
					//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
				})
				.finally( () => {
					this.setState({loading:false});
				})
			} else {
				this.setState({loading:false});
				//console.log("Não foi possível carregar a face do verso. Por favor, entre em contato com o administrador do sistema.")
			}
		})
		.catch(error => {
			this.setState({loading:false});
			//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
		})

		//envia uma requisição ao backend solicitando uma listagem com os países visíveis (status = 1)
		api.get('paises', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(pais => ({
				id_pais: `${pais.id}`,
				nome_pais: `${pais.nome}`
			}));
		})
		.then( paises => {
			this.setState({
				paises: paises,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os paises")
			this.setState({
				loading: false
			});
		})
		
		//envia uma requisição ao backend solicitando uma listagem com os emissores visíveis (status = 1)
		api.get('emissores', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(emissor => ({
				id_emissor: `${emissor.id}`,
				nome_emissor: `${emissor.nome}`
			}));
		})
		.then( emissores => {
			this.setState({
				emissores: emissores,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os emissores")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os períodos visíveis (status = 1)
		api.get('periodos', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(periodo => ({
				id_periodo: `${periodo.id}`,
				nome_periodo: `${periodo.nome}`
			}));
		})
		.then( periodos => {
			this.setState({
				periodos: periodos,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os periodos")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os formatos visíveis (status = 1)
		api.get('formatos', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(formato => ({
				id_formato: `${formato.id}`,
				nome_formato: `${formato.nome}`
			}));
		})
		.then( formatos => {
			this.setState({
				formatos: formatos,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os formatos")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os padrões monetário visíveis (status = 1)
		api.get('padroesMonetario', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(padraoM => ({
				id_padraoM: `${padraoM.id}`,
				nome_padraoM: `${padraoM.nome}`
			}));
		})
		.then( padroesM => {
			this.setState({
				padroesM: padroesM,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os padrões monetário")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os calendários visíveis (status = 1)
		api.get('calendarios', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(calendario => ({
				id_calendario: `${calendario.id}`,
				nome_calendario: `${calendario.nome}`
			}));
		})
		.then( calendarios => {
			this.setState({
				calendarios: calendarios,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os calendários")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os materiais visíveis (status = 1)
		api.get('materiais', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(material => ({
				id_material: `${material.id}`,
				nome_material: `${material.nome}`
			}));
		})
		.then( materiais => {
			this.setState({
				materiais: materiais,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os materiais")
			this.setState({
				loading: false
			});
		}) 
	}
	
	/**Armazena um valor em um determinado elemento do state sempre que solicitado*/
    onChange(e, type){
		const { name, value } = e.target

		//verifica se a quantidade de caracteres do valor ultrapassa o limite do campo "valorFace"
		if (name==="valorFace" && value.length >= 61)
			return
		
		//verifica se a quantidade de caracteres do valor ultrapassa o limite do campo "comentario"
		if (name==="comentario" && value.length >= 201)
			return
		
		//verifica se a quantidade de caracteres do valor ultrapassa o limite dos campos "descricaoVerso" ou "descricaoAnverso"
		if ((name==="descricaoVerso" || name==="descricaoAnverso") && value.length >= 101)
			return

		//verifica se a quantidade de caracteres do valor ultrapassa o limite dos campos "legendaAnverso" ou "legendaVerso"
		if ((name==="legendaAnverso" || name==="legendaVerso") && value.length >= 61)
			return
		
		//verifica se a quantidade de caracteres do valor ultrapassa o limite dos campos "gravadorAnverso" ou "gravadorVerso"
		if ((name==="gravadorAnverso" || name==="gravadorVerso") && value.length >= 51)
			return

		this.setState(
			{[name]: value}
		)

		//verifica se algum campo presente na etapa 1 (informações do item) foi modificado
		if (!this.state.modificouItem && type==="item"){
			//atribui "true" à flag que verifica se houve alguma modificação nos dados da etapa 1
			this.setState({modificouItem: true}) 
			//console.log("entrou")
		}

		//verifica se algum campo presente na etapa 2 (informações da face do anverso) foi modificado
		if (!this.state.modificouFaceAnverso && type==="face1"){
			//atribui "true" à flag que verifica se houve alguma modificação nos dados da etapa 2
			this.setState({modificouFaceAnverso: true})
			//console.log("entrou2")
		}

		//verifica se algum campo presente na etapa 3 (informações da face do verso) foi modificado
		if (!this.state.modificouFaceVerso && type==="face2"){
			//atribui "true" à flag que verifica se houve alguma modificação nos dados da etapa 3
			this.setState({modificouFaceVerso: true})
			//console.log("entrou2")
		}

		//console.log("type:", type)
	}

	/**Armazena um valor no state informando se o item está ou não fora de circulação (sempre que o checkbox for selecionado)*/
	onChangeCheckBox(e){
		this.setState({
			foraCirculacao: !this.state.foraCirculacao,
			modificouItem: true
		});
	}

	/**Função responsável por atualizar os dados do item */
	updateItem(){
		this.setState({loading:true})

		const item = {
			pais: this.state.pais,
			emissor: this.state.emissor === '' ? null : this.state.emissor,
			periodo: this.state.periodo === '' ? null : this.state.periodo,
			formato: this.state.formato === '' ? null : this.state.formato,
			padrao_monetario: this.state.padraoM,
			calendario: this.state.calendario === '' ? null : this.state.calendario,
			material: this.state.material === '' ? null : this.state.material,
			periodo_emissao: this.state.periodoEmissao === '' ? null : this.state.periodoEmissao,
			valor: this.state.valor === '' ? null : this.state.valor,
			valorFace: this.state.valorFace === '' ? null : this.state.valorFace,
			fora_circulacao: this.state.foraCirculacao,
			peso: this.state.peso === '' ? null : this.state.peso,
			largura: this.state.largura === '' ? null : this.state.largura,
			altura: this.state.altura === '' ? null : this.state.altura,
			espessura: this.state.espessura === '' ? null : this.state.espessura,
			comentario: this.state.comentario === '' ? null : this.state.comentario
		}

		//envia uma requisição ao backend para atualizar os dados do item (tabela item)
		api.put(`item/${this.state.itemId}`, item)
		.then( result => {
			//console.log("item", result)
			
			if(!result.data.error) {
				//console.log("Item atualizado com sucesso!");
			} else {
				this.setState({erroItem: true});
				//console.log("Não foi possível atualizar o item. Por favor, entre em contato com o administrador do sistema.")
			}
		})
		.catch(error => {
			this.setState({erroItem: true});
			//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
		})
		.finally(() => {
			this.setState({loading:false, modificouItem: false, funcItem: 1, modificou: true})
		})
	}

	/**Função responsável por atualizar os dados da face do anverso do item */
	updateFaceAnverso(){
		this.setState({loading:true})

		const faceAnverso = {
			gravador: this.state.gravadorAnverso === '' ? null : this.state.gravadorAnverso,
			legenda: this.state.legendaAnverso === '' ? null : this.state.legendaAnverso,
			descricao: this.state.descricaoAnverso === '' ? null : this.state.descricaoAnverso
		}

		//envia uma requisição ao backend para atualizar os dados da face do anverso do item (tabela face)
		api.put(`face/${this.state.itemId}/1`, faceAnverso)
		.then( face1 => {
			if(!face1.data.error) {
				//console.log("Face do anverso atualizada com sucesso!");				
			} else {
				this.setState({erroFace: true});
				//console.log("Não foi possível atualizar a face do anverso. Por favor, entre em contato com o administrador do sistema.")
			}
		})
		.catch(error => {
			this.setState({erroFace: true});
			//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
		})
		.finally(() => {
			this.setState({loading: false, modificouFaceAnverso: false, modificou: true})
			this.setState(prevState => {
				return {funcFace: prevState.funcFace + 1}
			 })
		})
	}

	/**Função responsável por atualizar os dados da face do verso do item */
	updateFaceVerso(){
		this.setState({loading:true})

		const faceVerso = {
			gravador: this.state.gravadorVerso === '' ? null : this.state.gravadorVerso,
			legenda: this.state.legendaVerso === '' ? null : this.state.legendaVerso,
			descricao: this.state.descricaoVerso === '' ? null : this.state.descricaoVerso
		}

		//envia uma requisição ao backend para atualizar os dados da face do verso do item (tabela face)
		api.put(`face/${this.state.itemId}/2`, faceVerso)
		.then( face2 => {
			if(!face2.data.error) {
				//console.log("Face do verso atualizada com sucesso!");
			} else {
				this.setState({erroFace: true});
				//console.log("Não foi possível atualizar a face do verso. Por favor, entre em contato com o administrador do sistema.")
			}
		})
		.catch(error => {
			this.setState({erroFace: true});
			//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
		})
		.finally(() => {
			this.setState({loading:false, modificouFaceVerso: false, modificou: true})
			this.setState(prevState => {
				return {funcFace: prevState.funcFace + 1}
			 })
		})
	}

	/**Função responsável por deletar/cadastrar as imagens da face do anverso do item */
	updateImgAnverso(){
		this.state.imgsAnverso.forEach(i => {
			this.setState({loading:true})

			if (i.status === "after"){ //verifica se a imagem foi inserida na listagem de imagens da face do anverso
				let formData = new FormData();
				formData.append('item', this.state.itemId);
				formData.append('posicao', 1);
				formData.append('imagem', i.img);
				formData.append('img-face', i.file, "img-face.png");

				//envia uma requisição ao backend para cadastrar uma nova imagem da face do anverso do item no banco de dados
				api.post("imagemFace", formData)
				.then(result1 =>{
					if (!result1.data.error){
						//console.log("Salvo com sucesso")
					}else{
						this.setState({erroImg: true});
						//console.log("Não foi possível cadastrar a imagem da face do anverso. Por favor, entre em contato com o administrador do sistema.")
					}
				})
				.catch(e => {
					this.setState({erroImg: true});
					//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
				})
				.finally( () => {
					this.setState({loading:false, modificou: true});
					this.setState(prevState => {
						return {funcImg: prevState.funcImg + 1}
					 })
				})
				
			}else if(i.status === "delete"){ //verifica se a imagem foi removida da listagem de imagens da face do anverso
				//envia uma requisição ao backend para excluir uma imagem da face do anverso do item no banco de dados
				api.delete(`imagemFace/${i.id}`)
				.then(result2 =>{
					if (!result2.data.error){
						//console.log("Removida com sucesso")
					}else{
						this.setState({erroImg: true});
						//console.log("Não foi possível remover a imagem da face do anverso. Por favor, entre em contato com o administrador do sistema.")
					}
				})
				.catch(e => {
					this.setState({erroImg: true});
					//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
				})
				.finally( () => {
					this.setState({loading:false, modificou: true});
					this.setState(prevState => {
						return {funcImg: prevState.funcImg + 1}
					 })
				})

			}else{
				this.setState({loading:false})
				this.setState(prevState => {
					return {funcImg: prevState.funcImg + 1}
				 })
			}
		});
	}

	/**Função responsável por deletar/cadastrar as imagens da face do verso do item */
	updateImgVerso(){
		this.state.imgsVerso.forEach(i => {
			this.setState({loading:true})

			if (i.status === "after"){ //verifica se a imagem foi inserida na listagem de imagens da face do verso
				let formData = new FormData();
				formData.append('item', this.state.itemId);
				formData.append('posicao', 2);
				formData.append('imagem', i.img);
				formData.append('img-face', i.file, "img-face.png");

				//envia uma requisição ao backend para cadastrar uma nova imagem da face do verso do item no banco de dados
				api.post("imagemFace", formData)
				.then(result1 =>{
					if (!result1.data.error){
						//console.log("Salvo com sucesso")
					}else{
						this.setState({erroImg: true});
						//console.log("Não foi possível cadastrar a imagem da face do verso. Por favor, entre em contato com o administrador do sistema.")
					}
				})
				.catch(e => {
					this.setState({erroImg: true});
					//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
				})
				.finally( () => {
					this.setState({loading:false, modificou: true});
					this.setState(prevState => {
						return {funcImg: prevState.funcImg + 1}
					 })
				})

			}else if(i.status === "delete"){ //verifica se a imagem foi removida da listagem de imagens da face do verso
				//envia uma requisição ao backend para excluir uma imagem da face do verso do item no banco de dados
				api.delete(`imagemFace/${i.id}`)
				.then(result2 =>{
					if (!result2.data.error){
						//console.log("Removida com sucesso")
					}else{
						this.setState({erroImg: true});
						//console.log("Não foi possível remover a imagem da face do verso. Por favor, entre em contato com o administrador do sistema.")
					}
				})
				.catch(e => {
					this.setState({erroImg: true});
					//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
				})
				.finally( () => {
					this.setState({loading:false, modificou: true});
					this.setState(prevState => {
						return {funcImg: prevState.funcImg + 1}
					 })
				})

			}else{
				this.setState({loading:false})
				this.setState(prevState => {
					return {funcImg: prevState.funcImg + 1}
				 })
			}
		});
	}
	
	/**Função executada após o usuário selecionar o botão "salvar"*/
    onSubmit(e){
		e.preventDefault()
		
		//verifica se algum dado da etapa 1 (informações do item) foi modificado
		if (this.state.modificouItem){
			this.updateItem() //atualiza o item
		}else{
			this.setState({funcItem: 1})
		}

		//verifica se algum dado da etapa 2 (informações da face do anverso) foi modificado
		if (this.state.modificouFaceAnverso){
			this.updateFaceAnverso() //atualiza a face do anverso
		}else{
			this.setState(prevState => {
				return {funcFace: prevState.funcFace + 1}
			 })
		}

		//verifica se algum dado da etapa 3 (informações da face do verso) foi modificado
		if(this.state.modificouFaceVerso){
			this.updateFaceVerso() //atualiza a face do verso
		}else{
			this.setState(prevState => {
				return {funcFace: prevState.funcFace + 1}
			 })
		}

		//verifica se há alguma imagem na listagem de imagens da face do verso 
		if (this.state.imgsVerso.length > 0){
			//console.log("oii")
			this.updateImgVerso() //atualiza a tabela com as imagens da face do verso
		}

		//verifica se há alguma imagem na listagem de imagens da face do anverso 
		if (this.state.imgsAnverso.length > 0){
			//console.log("oii2")
			this.updateImgAnverso() //atualiza a tabela com as imagens da face do anverso
		}
    }

	/**Remove uma determinada imagem da lista de imagens  */
	removeImage(e, index){
		e.preventDefault()
		if(this.state.etapa === 2){ //etapa da face do anverso
			let aux = this.state.imgsAnverso;

			if (aux[index].status === "before"){ //se a imagem já estiver cadastrada no banco
				aux[index].status = "delete" //altera o status para "delete"
			}else if(aux[index].status === "after"){ //se a imagem foi adicionada porém ainda não foi cadastrada no banco
				aux.splice(index, 1); //remove da listagem de imagens
			}

			this.setState({
				imgsAnverso: aux
			})
		}else if(this.state.etapa === 3){ //etapa da face do verso
			let aux = this.state.imgsVerso;
			
			if (aux[index].status === "before"){ //se a imagem já estiver cadastrada no banco
				aux[index].status = "delete" //altera o status para "delete"
			}else if(aux[index].status === "after"){ //se a imagem foi adicionada porém ainda não foi cadastrada no banco
				aux.splice(index, 1); //remove da listagem de imagens
			}

			this.setState({
				imgsVerso: aux
			})
		}
	}

	/**Vai para a próxima etapa */
	nextEtapa(e){
		e.preventDefault()
		this.setState(prevState => {
			return {etapa: prevState.etapa + 1}
		})
	}

	/**Retorna para a etapa anterior */
	previousEtapa(e){
		e.preventDefault()
		this.setState(prevState => {
			return {etapa: prevState.etapa - 1}
		})
	}

	/**Função que retorna o alert responsável pela mensagem (erro ou sucesso) referente à imagem, eventualmente exibida ao usuário */
	alertImage(){
        return(
            <Modal backdrop="static" keyboard={false} show={this.state.showAlertImage} 
            onHide={() => this.setState({showAlertImage: false})} className="modal-danger-img">
                <Modal.Header closeButton>
                    <div className="icon-box">
                        <i className="fas fa-times"></i>
                    </div>				
                    <h4 className="modal-title w-100">ERRO!</h4>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">{this.state.msgAlertImage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger btn-block" onClick={() => this.setState({showAlertImage: false})}>OK</button>
                </Modal.Footer>
            </Modal>
        )
    }

	/**Abre uma janela para o usuário escolher uma imagem */
	wrapImage(e) {
        document.getElementById("item-fileupload").click();
	}

	/**Armazena a imagem selecionada em uma listagem de imagens */
	loadImage(e, etapa) {
		
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];
		
		//verifica se nenhuma imagem foi selecionada
		if(e.target.files.length === 0){
			return;
		}

		//verifica se o tipo de arquivo selecionado é de fato uma imagem
		if (!/^(?:image\/bmp|image\/cis-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x-cmu-raster|image\/x-cmx|image\/x-icon|image\/x-portable-anymap|image\/x-portable-bitmap|image\/x-portable-graymap|image\/x-portable-pixmap|image\/x-rgb|image\/x-xbitmap|image\/x-xpixmap|image\/x-xwindowdump)$/i.test(file.type)){	
			//console.log("Erro - O tipo da imagem não é válido.");
			this.setState({showAlertImage: true, msgAlertImage: `O tipo do arquivo que você escolheu não é suportado. Escolha uma imagem.`})
			return;
		}

        const limitSizeImg = 3 //limite máximo da imagem em MB
        const sizeImg = ((file.size/1024)/1024).toFixed(4) //armazena o tamanho da imagem selecionada convertendo em MB

		//verifica se a imagem selecionada é maior ou igual ao limite pré-estabelecido
        if (sizeImg >= limitSizeImg){
            //console.log("Erro: Imagem muito grande, a imagem deve ter um tamanho menor que 3MB")
            this.setState({showAlertImage: true, msgAlertImage: `A imagem que você escolheu tem um tamanho maior ou igual a ${limitSizeImg}MB. Diminua o tamanho da imagem ou escolha outra.`})
            return;
        }

		//carrega a imagem
        reader.onloadend = () => {
			if (etapa === "2"){ //área face do anverso
				//adiciona a imagem selecionada na listagem de imagens da face do anverso
				const aux = this.state.imgsAnverso
				aux.push({
					file: file,
					img: reader.result,
					status: "after"
				})
				this.setState({imgsAnverso: aux});
			}else if(etapa === "3"){ //área face do verso
				//adiciona a imagem selecionada na listagem de imagens da face do verso
				const aux = this.state.imgsVerso
				aux.push({
					file: file,
					img: reader.result,
					status: "after"
				})
				this.setState({imgsVerso: aux});
			}
			
		}
		
		reader.readAsDataURL(file)
	}
	
	/**Função responsável por retornar o html que será apresentado na área de edição de item*/	
    render() {

		if (this.state.loading){
            return <Spinner />
		}

		//calcula a quantidade máxima de funções que deverão ser executadas ao chamar a função "submit"
		const quantMaxFuncCad = 3 + this.state.imgsAnverso.length + this.state.imgsVerso.length
		
		//condições para retornar a 'área da coleção' após o submit 
		if(this.state.funcImg + this.state.funcFace + this.state.funcItem === quantMaxFuncCad && 
		!this.state.modificou){ //se todas as funções foram executadas e nenhum campo de nenhuma etapa foi modificado
			//retorna para área da coleção
			return <Redirect to={{ pathname: '/colecao/'+this.props.match.params.idC, 
					state: { from: this.props.location, name: this.state.typeCollection}}} />
		}else if(this.state.funcImg + this.state.funcFace + this.state.funcItem === quantMaxFuncCad && 
		(this.state.erroImg || this.state.erroFace || this.state.erroItem)){ //se todas as funções foram executadas e houve algum erro
			//retorna para área da coleção passando por parâmetro uma mensagem de erro
			return <Redirect to={{ pathname: '/colecao/'+this.props.match.params.idC, 
					state: { from: this.props.location, name: this.state.typeCollection, 
					msg: {text: "Não foi possível atualizar o item corretamente. Por favor, entre em contato com o administrador do sistema.", variant: "danger"}}}} />
		}else if (this.state.funcImg + this.state.funcFace + this.state.funcItem === quantMaxFuncCad){ //se todas as funções foram executadas
			//retorna para área da coleção passando por parâmetro uma mensagem de sucesso
			return <Redirect to={{ pathname: '/colecao/'+this.props.match.params.idC, 
					state: { from: this.props.location, name: this.state.typeCollection, 
					msg: {text: "Item atualizado corretamente!", variant: "success"}}}} />
		}

		//inclui o compomente correspondente à etapa atual
		const retorno = 
		<form className="form-add-item">
			<ul id="progressbar">
				<li className={this.state.etapa >= 1 ? "active": ""}>Etapa 01</li>
				<li className={this.state.etapa >= 2 ? "active": ""}>Etapa 02</li>
				<li className={this.state.etapa >= 3 ? "active": ""}>Etapa 03</li>
			</ul>
			{this.state.etapa === 1 ? <ItemEtapa1 this={this}/> : 
			this.state.etapa === 2 ? <ItemEtapa2 this={this}/>  :
			this.state.etapa === 3 ? <ItemEtapa3 this={this}/>  : null}
	  	</form>

		return (
			<React.Fragment>
				<div className="background-bg background-collector"></div>
				<HeaderCollector history={this.props.history}/>
				{retorno}
				{this.alertImage()}
			</React.Fragment>
		) 
    }
}

export default EditItem