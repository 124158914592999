import jwt_decode from 'jwt-decode'

/** Verifica se o usuário está autenticado*/

export const isAuthenticated = () =>{
    const now = (Date.now() / 1000)

    //verifica se o usuário está conectado ao sistema (existência de um token)
    if (localStorage.usertoken){
        let token = jwt_decode(localStorage.usertoken)

        //verifica se a sessão ainda não foi expirada
        if (token.exp > now){
            return {
                status: true, 
                tipo_usuario_id: token.tipo_usuario_id + ''
            }
        }else{
            //desconecta o usuário removendo o token do LocalStorage
            localStorage.removeItem('usertoken')
            return {
                status: false
            }
        }
    }else{
        return {
            status: false
        }
    }
}