import React, { Component } from 'react'
import '../styles/Collection.css'
import api from '../services/api'
import NotImage from "../images/notImage.png";
import {FaTrashAlt, FaPencilAlt, FaHome} from 'react-icons/fa'
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi'
import {BsEyeFill} from 'react-icons/bs'
import jwt_decode from 'jwt-decode'
import Spinner from '../components/Spinner'
import HeaderCollector from '../components/HeaderCollector'
import { Link } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'

class DashboardCollector extends Component {
    
    constructor(){
        super()
        this.state = {
            loading: true,
            itens: [],
            itensAll: [],
            typeCollection: "" ,
            showAlert: false,
            alertMsg: "",
            alertVariant: "",
            itemSelectedId: "",
            limitPage: 25,
            offset: 0,
            filterType: "",
            filterData: "",
        }

        this.onChange = this.onChange.bind(this)
        this.onLimitChange = this.onLimitChange.bind(this)
        this.alertVisible = this.alertVisible.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.alertVisible = this.alertVisible.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
        this.loadingItem = this.loadingItem.bind(this)
        this.pagination = this.pagination.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.search = this.search.bind(this)
    }

    /**Função executada antes da página ser renderizada*/
    componentDidMount(){
        //console.log("área colecao", this.props)

        
        const location_state = this.props.history.location.state

        //verifica se alguma propriedade chamada state foi passada por parâmetro pela página anterior
        if (location_state !== undefined){

            //armazena o nome da coleção (passada por parâmetro pela página anterior)
            this.setState({typeCollection: location_state.name})

            //verifica se tem alguma mensagem (de erro ou de sucesso) enviada pela página anterior
            if (location_state.msg !== undefined){
                //console.log("continua: ", location_state.msg)
                
                this.setState({alertMsg: location_state.msg.text, alertVariant: location_state.msg.variant})
                this.alertVisible() //exibe a mensagem para o usuário
                
                let state = { ...location_state };
                
                //remove a mensagem da propriedade state passada por parâmetro para que, ao recarregar a página,
                //a mensagem não seja exibida novamente
                delete state.msg;
                this.props.history.replace({ ...this.props.history.location, state });
            }
        } 
        
        this.loadingItem()
    }

    /**Função responsável por carregar todos os itens vísiveis (status = 1) da coleção criados pelo usuário logado*/
    loadingItem(){
        const token = localStorage.usertoken
		const user_id = jwt_decode(token).id
        
        //Envia uma requisição ao backend solicitando uma listagem com todos os itens visíveis do usuário pertecentes a atual coleção
        api.get('itens', {
            headers: {
                status: true,
                usuario: user_id,
                colecao: this.props.match.params.id,
            }
        })
        .then( result => {
            //console.log(result.data)
            if (!result.data.error){
                this.setState({
                    itensAll: result.data,
                })
    
                if (this.state.filterType === ""){
                    this.setState({
                        itens: result.data
                    })
                }else{
                    this.search()
                }
            }else{
                this.setState({alertMsg: "Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.", alertVariant:"danger"})
                this.alertVisible()
            }
            
		})
		.catch(err => {
            this.setState({alertMsg: "Não foi possível carregar os itens da coleção.", alertVariant:"danger"})
            this.alertVisible()
		})
        .finally(f => {
            this.setState({loading: false})
        })
    }

    /**Exibe uma mensagem ao usuário (erro ou sucesso)*/
    alertVisible(){
        this.setState({showAlert:true})
        
        //define por quanto tempo a mensagem será exibida
        setTimeout(() => {
            if (this.state.showAlert)
                this.closeAlert()
        }, 5000);
    } 

    /**Fecha a mensagem exibida ao usuário */
    closeAlert(){
        this.setState({showAlert:false})    
    }

    /**Função que retorna o alert responsável pela mensagem (erro ou sucesso) eventualmente exibida ao usuário */
    alert(){
        return(
            <Alert show={this.state.showAlert} variant={this.state.alertVariant} 
            onClose={this.closeAlert} dismissible>

                <div className="icon">
                    {this.state.alertVariant === "success" ? <i className="fa fa-check"></i> :
                    (this.state.alertVariant === "danger" ? <i className="fa fa-times-circle"></i> : null)}
                </div>

                {this.state.alertVariant === "success" ? <strong>Sucesso! </strong> : 
                (this.state.alertVariant === "danger" ? <strong>Erro! </strong> : "")}
                
                {this.state.alertMsg}
            </Alert>
        )
    }

    /**Função responsável por remover um item selecionado */
    removeItem(e){
        e.preventDefault()
        //console.log("remover pais")
        this.setState({loading: true})

        //Envia uma requisição ao backend para remover determinado item da coleção
        api.delete(`item/${this.state.itemSelectedId}`)
        .then(result =>{
            if (!result.data.error){
                //console.log("Removido com sucesso")
                this.loadingItem() //recarregar os itens visíveis do usuário pertecentes à atual coleção
                this.setState({alertMsg: "O item foi removido com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao remover o item.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao remover o item.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false, itemSelectedId: ''})
            this.alertVisible() //exibe uma mensagem ao usuário informando o status do processo (erro ou sucesso)
        })
    }

    /**Função que retorna o modal responsável por solicitar a confirmação da remoção do item selecionado */
    modalRemove(){
        return (
            <div className="modal fade" id="modalRemoveItem" tabIndex="-1" role="dialog" aria-labelledby="modalRemoveItem" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title font-weight-normal" id="exampleModalLongTitle">Remover Item</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 className="col-form-label font-weight-normal">Você realmente deseja remover esse item?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Não</button>
                            <button type="button" onClick={this.removeItem} className="btn btn-primary" data-dismiss="modal">Sim</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Armazena um valor em um determinado elemento do state sempre que solicitado*/
    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    /**Armazena o limite máximo de itens que poderão ser visualizados por página de itens*/
    onLimitChange(e){
        this.setState({[e.target.name]: parseInt(e.target.value)})
    }

    /**Armazena a página de itens selecionada */
    onPageChange(page) {
        const limit = this.state.limitPage
        this.setState({offset: (page - 1) * limit});
    }

    /**Função responsável por controlar a paginação de itens */
    pagination(){
        const limit = this.state.limitPage //limite máximo de itens por página de itens
        const total = this.state.itens.length //total de itens
        const offset = this.state.offset 

        const MAX_ITEMS = 3; //quantidade máxima de opções (botões) no controle da paginação
        const MAX_LEFT = (MAX_ITEMS - 1) / 2; 
        const current = offset ? offset / limit + 1 : 1; //opção (botão) atual
        const pages = Math.ceil(total / limit); //total de páginas
        const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1);
        const first = Math.min(
            Math.max(current - MAX_LEFT, 1),
            maxFirst
        );
        
        //retorna o html responsável pelo controle da paginação
        return (
            <ul className="pagination d-flex justify-content-end flex-wrap pagination-flat pagination-success">
              <li className="page-item">
                <select className="form-control" 
                name="limitPage" value={this.state.limitPage} onChange={this.onLimitChange}>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                </select>
              </li>
              <li className="page-item">
                <button className="page-link"
                  onClick={() => this.onPageChange(current - 1)}
                  disabled={current === 1}
                >
                    <FiChevronLeft/>
                </button>
              </li>
              {Array.from({ length: Math.min(MAX_ITEMS, pages) })
                .map((_, index) => index + first)
                .map((page) => (
                  <li key={page} className={
                    page === current
                      ? "page-item active"
                      : "page-item"
                  }>
                    <button className="page-link"
                      onClick={() => this.onPageChange(page)}
                    >
                      {page}
                    </button>
                  </li>
                ))}
              <li className="page-item">
                <button className="page-link"
                  onClick={() => this.onPageChange(current + 1)}
                  disabled={current === pages}
                >
                    <FiChevronRight/>
                </button>
              </li>
            </ul>
        );
    }

    /**Função responsável por filtrar os itens de acordo com a opção de filtro selecionada 
     * e o valor informado no campo de pesquisa*/
    search(){
        //console.log("search")
        this.setState({loading: true})

        let array = []
        this.state.itensAll.forEach(i => {
            const filterData = this.state.filterData.toUpperCase()

            if (this.state.filterType === ""){ //se o tipo de filtro não for definido ("Todos")
                array.push(i) //exibir todos os itens
            }else if(this.state.filterType === "emissor"){ //se o tipo de filtro for definido como "emissor"
                if (i.emissor_nome === null){
                    if (filterData === ""){ //campo de pesquisa vazio
                        array.push(i) //exibe os itens que não tem emissor
                    }
                }else if(filterData === i.emissor_nome.toUpperCase()){
                    array.push(i) //exibe os itens em que o emissor é igual ao informado no campo de pesquisa
                }
            }else if(this.state.filterType === "material"){ //se o tipo de filtro for definido como "material"
                if (i.material_nome === null){
                    if (filterData === ""){ //campo de pesquisa vazio
                        array.push(i) //exibe os itens que não tem material
                    }
                }else if(filterData === i.material_nome.toUpperCase()){
                    array.push(i) //exibe os itens em que o material é igual ao informado no campo de pesquisa
                }
            }else if(this.state.filterType === "periodo"){ //se o tipo de filtro for definido como "periodo"
                if (i.periodo_nome === null){
                    if (filterData === ""){ //campo de pesquisa vazio
                        array.push(i) //exibe os itens que não tem periodo
                    }
                }else if(filterData === i.periodo_nome.toUpperCase()){
                    array.push(i) //exibe os itens em que o perido é igual ao informado no campo de pesquisa
                }
            }else if(this.state.filterType === "pais"){ //se o tipo de filtro for definido como "pais"
                if (i.pais_nome === null){
                    if (filterData === ""){ //campo de pesquisa vazio
                        array.push(i) //exibe os itens que não tem pais
                    }
                }else if(filterData === i.pais_nome.toUpperCase()){
                    array.push(i) //exibe os itens em que o pais é igual ao informado no campo de pesquisa
                }
            }else if(this.state.filterType === "ano"){ //se o tipo de filtro for definido como "ano"
                if (i.periodoEmissao === null || i.periodoEmissao === ""){
                    if (filterData === ""){ //campo de pesquisa vazio
                        array.push(i) //exibe os itens que não tem periodo de emissão
                    }
                }else{
                    const data = new Date(i.periodoEmissao)
                    const ano = data.getFullYear().toString()
                    //console.log(ano, filterData)
                    if (filterData === ano){
                        array.push(i) //exibe os itens em que o ano do periodo de emissão é igual ao informado no campo de pesquisa
                    }
                }
            } 
        });
        this.setState({itens: array, loading: false})
    }

    /**Função responsável por retornar o html que será apresentado na área da coleção*/
    render() {

        if (this.state.loading){
            return <Spinner/>
		}

        let itens = []
        let max_size = this.state.offset + this.state.limitPage //index máximo que o item pode ter na página 
        
        //console.log(this.state.offset, this.state.limitPage, max_size)

        //Cria um card para cada item da coleção que será apresentado na atual página
        for (let index = this.state.offset; index < Math.min(this.state.itens.length, max_size); index++) {
            const i = this.state.itens[index];

            //armazena a data em que o item foi atualizado
            let data = new Date(i.atualizadoEm)
            const dia = (data.getDate() < 10) ? '0' + data.getDate().toString() : data.getDate().toString();
            const mes = ((data.getMonth()+1) < 10) ? '0' + (data.getMonth()+1).toString() : (data.getMonth()+1).toString();
            const ano = data.getFullYear()
            const hora = (data.getHours() < 10) ? '0' + data.getHours().toString() : data.getHours().toString();
            const minutos = (data.getMinutes() < 10) ? '0' + data.getMinutes().toString() : data.getMinutes().toString();
            const dataFormat = dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos

            //insere o item em um array
            itens.push(
                <ul key={i.id} className="cart_list">
                    <li className="cart_item clearfix">

                        <div className="row cart-row-1">
                            <div className="col-md-3">
                                <div className="cart_item_image">
                                    <img src={i.imagem === null ? NotImage : (process.env.REACT_APP_BACKEND_URL + i.imagem)} 
                                    alt="imagem"/>
                                </div>
                                
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="cart_item_title">Valor</div>
                                        <div className="cart_item_text">R$ {i.valor === null ? "0.0" : i.valor}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="cart_item_title">Última Modificação</div>
                                        <div className="cart_item_text">{dataFormat}</div>
                                        
                                    </div>
                                    <div className="col-md-3 cart_item_actions">
                                        <div className="cart_item_title">Ações</div>
                                        <div className="cart_item_icons cart_item_text">
                                            <Link title="Visualizar" className="collection-actions"
                                            to={{ pathname: `${this.props.match.params.id}/${i.id}/visualizar`, state: { name: this.state.typeCollection} }}>
                                                <BsEyeFill/>
                                            </Link>
                                            <Link title="Editar" className="collection-actions"
                                            to={{ pathname: `${this.props.match.params.id}/${i.id}/editar`, state: { name: this.state.typeCollection} }}>
                                                <FaPencilAlt/>
                                            </Link>
                                                
                                            <button title="Remover" className="collection-actions" data-toggle="modal" 
                                            data-target="#modalRemoveItem" data-backdrop="static" data-keyboard="false"
                                            onClick={() => this.setState({itemSelectedId: i.id})}>
                                                <FaTrashAlt/>
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                        </div>
                    </li>
                </ul>
            )
        }

        //inclui todos os cards criados
        const retorno = 
        <div>
            <div className="container-fluid container-collection">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="cart_container">
                            {this.alert()}
                            <div className="cart_title"> 
                                <div className="d-flex align-items-center">
                                    <Link to="/dashboard">
                                        <FaHome/>
                                    </Link>
                                    {this.state.typeCollection}
                                </div>
                                
                                <Link className="btn btn-success cart_button_add" to={{ pathname: this.props.match.params.id+"/cadastrar", state: { name: this.state.typeCollection} }}>
                                    <i className="fas fa-plus mr-1"></i>  Novo item
                                </Link>
                            </div>

                            <div className="cart_filter row mb-1">
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <div className="mr-1">
                                            <select className="form-control" 
                                            name="filterType" value={this.state.filterType} onChange={this.onChange}>
                                                <option value="">Todos</option>
                                                <option value="ano">Ano</option>
                                                <option value="emissor">Emissor</option>
                                                <option value="material">Material</option>
                                                <option value="periodo">Período</option>
                                                <option value="pais">País</option>
                                            </select>
                                        </div>
                                        
                                        <input name="filterData" className="form-control mr-1" value={this.state.filterData} onChange={this.onChange}
                                            disabled={this.state.filterType === ""} type="text" placeholder="Pesquisar Itens"/>
                                        <button className="btn btn-primary" type="button" onClick={this.search}>
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <small>{this.state.itens.length} {this.state.itens.length > 1 ? "itens encontrados" : "item encontrado"} </small>
                            </div>
                            <div className="cart_items">
                                {itens}                            
                            </div>
                            
                            <div className="cart_footer">
                                {itens.length > 0 ? this.pagination(): null}
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        //console.log(this.state)
        return (
            <React.Fragment>
                <div className="background-bg background-collector"></div>
                <HeaderCollector history={this.props.history}/>
                {retorno}
                {this.modalRemove()}
            </React.Fragment>
            
        ) 
    }
}

export default DashboardCollector