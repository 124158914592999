import React, { Component } from 'react'

class ItemEtapa1 extends Component {
    
    componentDidMount(){
        //console.log("Etapa 1")
    }

	/**Função responsável por retornar o html que será apresentado na etapa 1 (informações do item)
	 * da área de cadastro ou da área de edição de item*/
    render() {

		//armazena o "this" do componente pai (RegisterItem ou EditItem) na constante "thisSuper"
        const thisSuper = this.props.this //necessário para acessar as funções e propriedades do componente pai
		
        return (
			<fieldset>
				<section className="section-head text-center">
					<strong>Informações Gerais</strong>
				</section>
				<section className="section-body text-left">

					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<div className="form-group">
									<label className="lb-title">Coleção</label>
									<input type="text" disabled className={"form-control"} placeholder="Coleção" 
									name="colecao" value={thisSuper.state.typeCollection}/>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label className="lb-title">País</label>
								<select className="form-control" name="pais" placeholder="Pais" 
								value={thisSuper.state.pais} aria-label="Pais" onChange={(e) => thisSuper.onChange(e, "item")}>
									{
										thisSuper.state.paises.map( function(pais) {
											return <option key={pais.id_pais} value={pais.id_pais}>{pais.nome_pais}</option>;
										})
									}
								</select>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="lb-title">Padrão Monetário</label>
								<select className="form-control"
								name="padraoM" placeholder="Padrão Monetário" value={thisSuper.state.padraoM} aria-label="Padrão Monetário" onChange={(e) => thisSuper.onChange(e, "item")}>
									{
										thisSuper.state.padroesM.map( function(padraoM) {
											return <option key={padraoM.id_padraoM} value={padraoM.id_padraoM}>{padraoM.nome_padraoM}</option>;
										})
									}
								</select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label className="lb-title">Emissor</label>
								<select className="form-control" 
								name="emissor" placeholder="Emissor" value={thisSuper.state.emissor} aria-label="Emissor" onChange={(e) => thisSuper.onChange(e, "item")}>
									<option className="hidden" value="">Sem emissor</option>
									{
										thisSuper.state.emissores.map( function(emissor) {
											return <option key={emissor.id_emissor} value={emissor.id_emissor}>{emissor.nome_emissor}</option>;
										})
									}
								</select>
							</div>
						</div>
					</div>
					
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="lb-title">Período</label>
								<select className="form-control" 
								name="periodo" placeholder="Período" value={thisSuper.state.periodo} aria-label="Período" onChange={(e) => thisSuper.onChange(e, "item")}>
									<option className="hidden" value="">Sem período</option>
									{
										thisSuper.state.periodos.map( function(periodo) {
											return <option key={periodo.id_periodo} value={periodo.id_periodo}>{periodo.nome_periodo}</option>;
										})
									}
								</select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label className="lb-title">Formato</label>
								<select className="form-control"
								name="formato" placeholder="Formato" value={thisSuper.state.formato} aria-label="Formato" onChange={(e) => thisSuper.onChange(e, "item")}>
									<option className="hidden" value="">Sem formato</option>
									{
										thisSuper.state.formatos.map( function(formato) {
											return <option key={formato.id_formato} value={formato.id_formato}>{formato.nome_formato}</option>;
										})
									}
								</select>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label className="lb-title">Calendário</label>
								<select className="form-control" 
								name="calendario" placeholder="Calendário" value={thisSuper.state.calendario} aria-label="Calendário" onChange={(e) => thisSuper.onChange(e, "item")}>
									<option className="hidden" value="">Sem calendário</option>
									{
										thisSuper.state.calendarios.map( function(calendario) {
											return <option key={calendario.id_calendario} value={calendario.id_calendario}>{calendario.nome_calendario}</option>;
										})
									}
								</select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label className="lb-title">Material</label>
								<select className="form-control"
								name="material" placeholder="Material" value={thisSuper.state.material} aria-label="Material" onChange={(e) => thisSuper.onChange(e, "item")}>
									<option className="hidden" value="">Sem material</option>
									{
										thisSuper.state.materiais.map( function(material) {
											return <option key={material.id_material} value={material.id_material}>{material.nome_material}</option>;
										})
									}
								</select>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-3">
							<div className="form-group">
								<label className="lb-title">Largura</label>
								<input type="number" className="form-control" placeholder="Largura em mm" 
								name="largura" value={thisSuper.state.largura} onChange={(e) => thisSuper.onChange(e, "item")}/>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group">
								<div className="form-group">
									<label className="lb-title">Altura</label>
									<input type="number" className="form-control" placeholder="Altura em mm" 
									name="altura" value={thisSuper.state.altura} onChange={(e) => thisSuper.onChange(e, "item")}/>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group">
								<div className="form-group">
									<label className="lb-title">Espessura</label>
									<input type="number" className="form-control" placeholder="Espessura em mm" 
									name="espessura" value={thisSuper.state.espessura} onChange={(e) => thisSuper.onChange(e, "item")}/>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group">
								<label className="lb-title">Peso</label>
								<input type="number" className="form-control" placeholder="Peso em g" 
								name="peso" value={thisSuper.state.peso} onChange={(e) => thisSuper.onChange(e, "item")}/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<div className="form-group">
									<label className="lb-title">Período de Emissão</label>
									<input type="date" className="form-control" placeholder="Período de Emissão" 
									name="periodoEmissao" value={thisSuper.state.periodoEmissao} onChange={(e) => thisSuper.onChange(e, "item")}/>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label className="lb-title">Valor</label>
										<input type="number" className="form-control" placeholder="Valor em reais" 
										name="valor" value={thisSuper.state.valor} onChange={(e) => thisSuper.onChange(e, "item")}/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label className="lb-title">Valor da Face</label>
										<input type="text" className="form-control" name="valorFace" placeholder="Valor da face"
										value={thisSuper.state.valorFace} onChange={(e) => thisSuper.onChange(e, "item")}/>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div className="row">
						<div className="col-md-12">
							<input type="checkbox" defaultChecked={thisSuper.state.foraCirculacao} 
							id="foraCirculacao" name="foraCirculacao" className="mr-2" 
							value={thisSuper.state.foraCirculacao} onChange={thisSuper.onChangeCheckBox}/>	
							<label htmlFor="foraCirculacao">Fora de circulação. </label>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12">
							<label className="lb-title">Comentário</label>
							<textarea className="form-control" placeholder="Comentário" 
							name="comentario" value={thisSuper.state.comentario} onChange={(e) => thisSuper.onChange(e, "item")}/>
						</div>
					</div>
					
					<div className="row">
						<div className="col-md-12 text-center mt-4 row-action-buttons">
							<input type="button" name="previous" className="previous action-button btn btn-secondary" value="Sair" 
							onClick={e => thisSuper.props.history.goBack()}/>
							<input type="button" name="next" className="next action-button btn btn-primary" 
							onClick={thisSuper.nextEtapa} value="Próximo" />
						</div>
					</div>
					
				</section>
			</fieldset>
		)
    }
}

export default ItemEtapa1