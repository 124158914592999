import React, { Component } from 'react'
import '../styles/RegisterUser.css'
import {Link} from 'react-router-dom'
import api from '../services/api'
import Spinner from '../components/Spinner'
import ImgCollect from "../images/collect.png"
import InputMask from "react-input-mask"


class RegisterUser extends Component {
	
    constructor(){
        super()
        this.state = {
			loading: true,
			email:'',
			senha:'',
			nome:'',
			telefone:'',
			nascimento:'',
			municipio:'',
			municipios: [],
			estado:'',
			estados: [],
			sexo: '',
			bairro: '',
			cep: '',
			numero: '',
			logradouro: '',
			complemento: '',
			validation: {
				email: {wasValidated: false},
				senha: {wasValidated: false},
				nome: {wasValidated: false},
				telefone: {wasValidated: false},
				nascimento: {wasValidated: false},
				estado: {wasValidated: false},
				municipio: {wasValidated: false},
				sexo: {wasValidated: false},
				bairro: {wasValidated: false},
				cep: {wasValidated: false},
				numero: {wasValidated: false},
				logradouro: {wasValidated: false}
			},
        }
		
		this.changeEstado = this.changeEstado.bind(this)
		this.setMunicipios = this.setMunicipios.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }
    
	/**Função executada antes da página ser renderizada*/
    componentDidMount(){

		//envia uma requisição ao backend com o objetivo de receber uma listagem com os estados
		api.get('estados').then( response => {
			return response.data.map(estado => ({
				id_estado: `${estado.id}`,
				nome_estado: `${estado.nome}`
			}));
		})
		.then( estados => {
			//console.log(estados)
			this.setState({
				estados: estados,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar estados")
			this.setState({
				loading: false
			});
		}) 		
	}
	
	/**Função responsável pela validação de um campo */
	validateField(name, value) {

		let validation = { ...this.state.validation };

        switch (name) {

            case "nome": //valida o campo "nome"
				if(!value || value.length < 5) {
					validation.nome = {
						hasError: true,
						errorCode:'NOM001',
						errorMessage:"O campo 'nome' deve possuir pelo menos 5 (cinco) caracteres."
					}
				} else {
					validation.nome = {
						hasError: false
					}
				}
				validation.nome.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, nome: validation.nome }}))
				break;
			case "sexo": //valida o campo "sexo"
				if(!value || !(value === "M" || value === "F")) {
					validation.sexo = {
						hasError: true,
						errorCode:'NOM001',
						errorMessage:"O campo 'sexo' deve ser selecionado."
					}
				} else {
					validation.sexo = {
						hasError: false
					}
				}
				validation.sexo.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, sexo: validation.sexo }}))
				break;
			case "telefone": //valida o campo "telefone"
				//const phoneRegExp = RegExp(/^\(\d{2}\)\s\d{4,5}-\d{4}$/)
				const phoneRegExp = RegExp(/^\(\d{2}\)\s\d{5}-\d{4}$/)

				if(!phoneRegExp.test(value)) {
					validation.telefone = {
						hasError: true,
						errorCode:'EMA001',
						errorMessage:"O 'telefone' deve possuir o formato '(99) 99999-9999'"
					}
				} else {
					validation.telefone = {
						hasError: false
					}
				}
				validation.telefone.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, telefone: validation.telefone }}))
				break;
            case "email": //valida o campo "email"
				const emailRegExp = RegExp(/^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/)

				if(!emailRegExp.test(value)) {
					validation.email = {
						hasError: true,
						errorCode:'EMA001',
						errorMessage:"O campo 'email' deve possuir um formato válido."
					}
				} else {
					validation.email = {
						hasError: false
					}
				}
				validation.email.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, email: validation.email }}))
                break;
            case "senha": //valida o campo "senha"
				const passwordRegExp = RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)

				if(!passwordRegExp.test(value)) {
					validation.senha = {
						hasError: true,
						errorCode:'EMA001',
						errorMessage:"O campo 'senha' deve possuir 8 caracteres, sendo, no mínimo, uma letra maiúscula, uma letra minúscula e um número."
					}
				} else {
					validation.senha = {
						hasError: false
					}
				}
				validation.senha.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, senha: validation.senha }}))
				break;
			case "estado": //valida o campo "estado"
				if(value === "" || !value) {
					validation.estado = {
						hasError: true,
						errorCode:'EST001',
						errorMessage:"O campo 'estado' deve ser selecionado."
					}
				} else {
					validation.estado = {
						hasError: false
					}
				}
				validation.estado.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, estado: validation.estado }}))
				break;
			case "municipio": //valida o campo "município"
				if(value === "" || !value) {
					validation.municipio = {
						hasError: true,
						errorCode:'MUN001',
						errorMessage:"O campo 'município' deve ser selecionado."
					}
				} else {
					validation.municipio = {
						hasError: false
					}
				}
				validation.municipio.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, municipio: validation.municipio }}))
				break;
			case "nascimento": //valida o campo "nascimento"
				const birthdayRegExp = RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)

				if(!birthdayRegExp.test(value)) {
					validation.nascimento = {
						hasError: true,
						errorCode:'NAS001',
						errorMessage:"O campo 'nascimento' deve possuir um formato válido."
					}
				} else {
					validation.nascimento = {
						hasError: false
					}
				}
				validation.nascimento.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, nascimento: validation.nascimento }}))
                break;
			case "bairro": //valida o campo "bairro"
				if(!value || value.length < 2) {
					validation.bairro = {
						hasError: true,
						errorCode:'NOM001',
						errorMessage:"O campo 'bairro' deve possuir pelo menos 2 (dois) caracteres."
					}
				} else {
					validation.bairro = {
						hasError: false
					}
				}
				validation.bairro.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, bairro: validation.bairro }}))
				break;
			case "logradouro": //valida o campo "logradouro"
				if(!value || value.length < 2) {
					validation.logradouro = {
						hasError: true,
						errorCode:'NOM001',
						errorMessage:"O campo 'logradouro' deve possuir pelo menos 2 (dois) caracteres."
					}
				} else {
					validation.logradouro = {
						hasError: false
					}
				}
				validation.logradouro.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, logradouro: validation.logradouro }}))
				break;
			case "numero": //valida o campo "número"
				const numRegExp = RegExp(/^[0-9]+$/)
				if(!numRegExp.test(value)) {
					validation.numero = {
						hasError: true,
						errorCode:'EST001',
						errorMessage:"O campo 'número' deve ser um valor natural."
					}
				} else {
					validation.numero = {
						hasError: false
					}
				}
				validation.numero.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, numero: validation.numero }}))
				break;
			case "cep": //valida o campo "cep"
				const cepRegExp = RegExp(/^[0-9]{2}.[0-9]{3}-[0-9]{3}$/)

				if(!cepRegExp.test(value)) {
					validation.cep = {
						hasError: true,
						errorCode:'EMA001',
						errorMessage:"O 'cep' deve possuir o formato '00.000-000'"
					}
				} else {
					validation.cep = {
						hasError: false
					}
				}
				validation.cep.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, cep: validation.cep }}))
				break;
            default:
                break;
        }

	}
	
	/**Verifica se todos os campos estão válidos*/
	validate() {

		let validation = { ...this.state.validation };

		let wasValidated = true
		for( var key in validation ) {
			if(!validation[key].wasValidated) {
				this.validateField(key, this.state[key])
				wasValidated = false;
			}
		}

		if(!wasValidated) return false;

		validation = { ...this.state.validation };

		return 	!( 	validation.nome.hasError || 
					validation.telefone.hasError ||
					validation.sexo.hasError ||
					validation.email.hasError ||
					validation.senha.hasError ||
					validation.estado.hasError ||
					validation.municipio.hasError ||
					validation.nascimento.hasError || 
					validation.cep.hasError ||
					validation.bairro.hasError ||
					validation.logradouro.hasError ||
					validation.numero.hasError)

	}
	
	/**Armazena um valor em um determinado elemento do state e executa a função 
	 *que verifica se esse valor é válido para o elemento em questão sempre que solicitado
	*/
    onChange(e){
		const { name, value } = e.target
		
		this.setState(
			{[name]: value}, 
			() => { this.validateField(name, value) }
		)
	}

	/**Armazena o estado selecionado no state e chama a função que carrega os municípios desse estado*/
	changeEstado(e){
		this.onChange(e)
		
		this.setState(prevState => ({
			...prevState,
			municipio: '',
			validation: {
				...prevState.validation,
				municipio: {
					wasValidated: false,
					hasError: null
				}
			}
		}))
		this.setMunicipios(e.target.value)
	}
	
	/**Função responsável por carregar os municípios de um determinado estado*/
	setMunicipios(estado){
		//console.log(estado)
		if (estado !== ""){
			//Envia uma requisição ao backend com o objetivo de receber uma listagem com os municípios de um determinado estado
			api.get('cidades', {
				headers: {
					estado: estado
				}
			}).then( response => {
				return response.data.map(municipio => ({
					id_municipio: `${municipio.id}`,
					nome_municipio: `${municipio.nome}`
				}));
			})
			.then( municipios => {
				this.setState({
					municipios
				});
			})
			.catch(err => {
				//console.log("Erro ao carregar municípios")
			})

		}else{
			this.setState({
				municipios:[]
			});
		}
		
	}

	/**Função executada após o usuário tentar realizar o seu cadastro, submetendo as suas informações solicitadas*/
    onSubmit(e){
		e.preventDefault()
		this.setState({loading:true})
		
		//console.log('subimitteeee')

        if (this.validate()) { //verifica se todos os campos estão válidos

			const user = {
				email: this.state.email,
				senha: this.state.senha,
				nome: this.state.nome,
				telefone: this.state.telefone, 
				sexo: this.state.sexo,
				nascimento: this.state.nascimento,
				cidade: this.state.municipio,
				bairro: this.state.bairro,
				numero: this.state.numero,
				logradouro: this.state.logradouro,
				complemento: this.state.complemento,
				cep: this.state.cep,
			}

			//envia uma requisição ao backend com o objetivo de verificar se o email informado está disponível
			api.post('checkEmail', {email: user.email})
			.then( res => {
				//console.log("oi", res)
				if(res.data.status) {
					this.setState({emailDuplicated: false});

					//envia uma requisição ao backend para efetivar o cadastro do usuário no sistema
					api.post('user', user)
					.then( res => {
						if(res.data.id) {
							this.props.history.push(`/`) //redireciona para a área de login			
						} else {
							this.setState({loading:false});
							//console.log("Não foi possível cadastrar o usuário. Por favor, entre em contato com o administrador do sistema.")
						}
					})
					.catch(error => {
						this.setState({loading:false});
						//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
					})

				} else {
					this.setState({emailDuplicated: true});
				}
			})
			.catch(error => {
				this.setState({loading:false});
				//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
			})
			.finally( () => {
				this.setState({loading:false});
			})
		}else
			this.setState({loading:false})
    }
	
	/**Função responsável por retornar o html que será apresentado à página de cadastro de usuário*/
    render() {
		if (this.state.loading){
            return <Spinner />
		}

		const { validation } = this.state;
		//console.log(validation)
		const retorno = 
		<div className="container-register">
			<div className="component-middle">
				<div className="container register">
					<div className="row">
						<div className="col-md-3 register-left">
							<img src={ImgCollect} alt=""/>
							<h3>Bem-Vindo!</h3>
							<p>Crie uma conta e organize suas coleções com mais facilidade e praticidade.</p>
							<Link to = "/">
								<input type="submit" name="" value="Login"/>
							</Link><br/>
						</div>
						<div className="col-md-9 register-right">
							
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
									<h3 className="register-heading">Cadastre-se</h3>
									<form onSubmit={this.onSubmit} encType="multipart/form-data" noValidate>
										<div className="row register-form">
											<div className="col-md-6">
												<div className="form-group">
													<input type="text" className={!validation.nome.wasValidated ? "form-control": validation.nome.hasError ? "is-invalid form-control" : "is-valid form-control"} 
													placeholder="Nome *"name="nome" value={this.state.nome} onChange={this.onChange}/>
													{validation.nome.hasError ? (<span className="invalid-feedback">{validation.nome.errorMessage}</span>) : null}
												</div>
												<div className="form-group">
													<input type="email" className={!validation.email.wasValidated ? "form-control": (validation.email.hasError || this.state.emailDuplicated) ? "is-invalid form-control" : "is-valid form-control"}
													placeholder="Email *" name="email" value={this.state.email} onChange={this.onChange}/>
													{validation.email.hasError ? (<span className="invalid-feedback">{validation.email.errorMessage}</span>) : null}
													{this.state.emailDuplicated ? (<span className="invalid-feedback">O email informado já se encontra em uso.</span>) : null}
												</div>
												<div className="form-group">
													<input type="password" className={!validation.senha.wasValidated ? "form-control": validation.senha.hasError ? "is-invalid form-control" : "is-valid form-control"} 
													placeholder="Senha *" name="senha" value={this.state.senha} onChange={this.onChange}/>
													{validation.senha.hasError ? (<span className="invalid-feedback">{validation.senha.errorMessage}</span>) : null}
												</div>
												<div className="form-group">
													
													<InputMask className={!validation.telefone.wasValidated ? "form-control": validation.telefone.hasError ? "is-invalid form-control" : "is-valid form-control"} 
													mask="(99) 99999-9999" placeholder="Telefone *" name="telefone" value={this.state.telefone} onChange={this.onChange}/>
													{validation.telefone.hasError ? (<span className="invalid-feedback">{validation.telefone.errorMessage}</span>) : null}
												</div>
												<div className="form-group">
													<input type="date" className={!validation.nascimento.wasValidated ? "form-control": validation.nascimento.hasError ? "is-invalid form-control" : "is-valid form-control"} 
													placeholder="Nascimento *" name="nascimento" value={this.state.nascimento} onChange={this.onChange}/>
													{validation.nascimento.hasError ? (<span className="invalid-feedback">{validation.nascimento.errorMessage}</span>) : null}
												</div>
												<div className="form-group">
													<div className={!validation.sexo.wasValidated ? "maxl": validation.sexo.hasError ? "is-invalid maxl" : "is-valid maxl"}>
														<label className="radio inline mr-3"> 
															<input type="radio" name="sexo" value="M" onChange={this.onChange} 
															checked={this.state.sexo === "M"}/>
															<span> Masculino </span> 
														</label>
														<label className="radio inline"> 
															<input type="radio" name="sexo" value="F" onChange={this.onChange}
															checked={this.state.sexo === "F"}/>
															<span> Feminino </span> 
														</label>
													</div>
													{validation.sexo.hasError ? (<span className="invalid-feedback">{validation.sexo.errorMessage}</span>) : null}
												</div>
											</div>
											<div className="col-md-6">
												
												<div className="row">
													<div className="col-md-6 padding-r">
														<div className="form-group">
															<select className={!validation.estado.wasValidated ? "form-control": validation.estado.hasError ? "is-invalid form-control" : "is-valid form-control"}  
															name="estado" placeholder="Estado" value={this.state.estado} aria-label="Estado" onChange={this.changeEstado}>
																<option className="hidden" value="" disabled>estado</option>
																{
																	this.state.estados.map( function(estado) {
																		return <option key={estado.id_estado} value={estado.id_estado}>{estado.nome_estado}</option>;
																	})
																}
															</select>
															{validation.estado.hasError ? (<span className="invalid-feedback">{validation.estado.errorMessage}</span>) : null}
														</div>
													</div>
													<div className="col-md-6 padding-l">
														<div className="form-group">
															<select className={!validation.municipio.wasValidated ? "form-control": validation.municipio.hasError ? "is-invalid form-control" : "is-valid form-control"}  
															name="municipio" placeholder="Municipio" value={this.state.municipio} aria-label="Município" onChange={this.onChange}>
																<option className="hidden" value="" disabled>município</option>
																{
																	this.state.municipios.map( function(municipio) {
																		return <option key={municipio.id_municipio} value={municipio.id_municipio}>{municipio.nome_municipio}</option>;
																	})
																}
															</select>
															{validation.municipio.hasError ? (<span className="invalid-feedback">{validation.municipio.errorMessage}</span>) : null}
														</div>
													</div>
												</div>
												
												<div className="form-group">
													<input type="text" className={!validation.bairro.wasValidated ? "form-control": validation.bairro.hasError ? "is-invalid form-control" : "is-valid form-control"} 
													placeholder="Bairro *" name="bairro" value={this.state.bairro} onChange={this.onChange}/>
													{validation.bairro.hasError ? (<span className="invalid-feedback">{validation.bairro.errorMessage}</span>) : null}
												</div>

												<div className="row">
													<div className="col-md-6 padding-r">
														<div className="form-group">
															<InputMask className={!validation.cep.wasValidated ? "form-control": validation.cep.hasError ? "is-invalid form-control" : "is-valid form-control"} 
															mask="99.999-999" placeholder="CEP *" name="cep" value={this.state.cep} onChange={this.onChange}/>
															
															{validation.cep.hasError ? (<span className="invalid-feedback">{validation.cep.errorMessage}</span>) : null}
														</div>
													</div>
													<div className="col-md-6 padding-l">
														<div className="form-group">
															<input type="text" className={!validation.numero.wasValidated ? "form-control": validation.numero.hasError ? "is-invalid form-control" : "is-valid form-control"}  
															placeholder="Número *" name="numero" value={this.state.numero} onChange={this.onChange}/>
															{validation.numero.hasError ? (<span className="invalid-feedback">{validation.numero.errorMessage}</span>) : null}
														</div>
													</div>	
												</div>
												
												
												<div className="form-group">
													<input type="text" className={!validation.logradouro.wasValidated ? "form-control": validation.logradouro.hasError ? "is-invalid form-control" : "is-valid form-control"}  
													placeholder="Logradouro *" name="logradouro" value={this.state.logradouro} onChange={this.onChange}/>
													{validation.logradouro.hasError ? (<span className="invalid-feedback">{validation.logradouro.errorMessage}</span>) : null}
												</div>
												
												<div className="form-group">
													<input type="text" className="form-control" placeholder="Complemento"
													name="complemento" value={this.state.complemento} onChange={this.onChange}/>
												</div>

												<input type="submit" className="btnRegister"  value="Cadastrar"/>
											</div>
										</div>
									</form>
									
								</div>
								
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		return (
			<React.Fragment>
				<div className="background-bg background-register"></div>
				{retorno}
			</React.Fragment>
			
		) 
    }
}

export default RegisterUser