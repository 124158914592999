import React, { Component } from 'react'
import '../styles/Login.css'
import {Link} from 'react-router-dom'
import api from '../services/api'
import jwt_decode from 'jwt-decode'
import Spinner from '../components/Spinner'

class Login extends Component {
    
    constructor(){
        super()
        this.state = {
            email: '',
            senha: '',
            loading: true,
            error: <br></br>,
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.redirectUser = this.redirectUser.bind(this)
    }

    /**Função executada antes da página ser renderizada*/
    componentDidMount(){
        this.redirectUser()    
    }

    /** Retorna o tipo de ususário que está logado */
    getUserType(){
        const token = localStorage.usertoken
		const decoded = jwt_decode(token)
		//console.log(decoded)
        return decoded.tipo_usuario_id
    }

    /** Redireciona para a área do colecionador ou para a área do administrador dependendo do tipo de usuário logado*/
    redirectUser(){
        //verifica se o usuário está logado no sistema
        if (localStorage.usertoken){
            const tipo = this.getUserType()
            if (tipo === 1) //usuário do tipo administrador
                this.props.history.push('/admin')
            else if(tipo === 2) //usuário do tipo colecionador
                this.props.history.push('/dashboard')
            else{ //tipo de usuário desconhecido
                localStorage.clear() //limpa o LocalStorage
                this.props.history.push('/') //volta para a página de login
            }
        }else{
            this.setState({loading:false})
        }
    }

    /**Armazena um valor em um determinado elemento do state sempre que solicitado*/
    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    /**Função executada após o usuário tentar entrar no sistema, submetendo suas informações (nome de usuário e senha)*/
    onSubmit(e){
        e.preventDefault()
        this.setState({loading:true, error:<br></br>})
        const user = {
            email: this.state.email,
            senha: this.state.senha
        }

        //console.log(this.state)

        //Envia uma requisição ao backend com o objetivo de verificar se as credenciais informadas pelo usuário estão corretas
        api.post('login', user)
        .then(res => {
            //console.log(res)
            if (res.data.status){ 
                //Efetua o login armazenando o token gerado no LocalStorage
                localStorage.setItem('usertoken', res.data.token)
            }else{
                this.setState({loading:false, error: "Conta inválida."})
            }
            
            this.redirectUser();
        })
        .catch(err => {
            //console.log(err)
            this.setState({loading:false, error: "Não foi possível realizar o login!"})
        })
        
    }

    /**Função responsável por retornar o html que será apresentado à página de login*/
    render() {

        if (this.state.loading){
            return <Spinner/>
		}

        const retorno =         
        <div className="container-login">
            <div className="component-middle">
                <div className="card card-container">                
                    <form className="form-signin" onSubmit={this.onSubmit}>
                        <p id="reauth-email" className="reauth-email" 
                        name="error" value={this.state.error} onChange = {this.onChange}>{this.state.error}</p>
                        
                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-user"></i></span>
                            </div>
                            <input type="email" id="inputEmail" className="form-control" placeholder="Email" required 
                            name="email" value={this.state.email} onChange = {this.onChange}/>
                        </div>

                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-key"></i></span>
                            </div>
                            <input type="password" id="inputPassword" className="form-control" placeholder="Senha" required 
                            name="senha" value={this.state.senha} onChange = {this.onChange}/>
                        </div>
                        
                        <button className="btn btn-lg btn-block btn-signin" type="submit">Entrar</button>
                    </form>
                    <div className="login-footer">
                        Não tem uma conta? 
                        <Link to = "/cadastrar" className="link-register">
                            Cadastre-se aqui.
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    
        return (
            <React.Fragment>
                <div className="background-bg"></div>
                {retorno}
            </React.Fragment>
            
        ) 
    }
}

export default Login
