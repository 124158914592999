import React, { Component } from 'react'
import '../styles/RegisterEditItem.css'
import {Redirect} from 'react-router-dom'
import api from '../services/api'
import Spinner from '../components/Spinner'
import HeaderCollector from '../components/HeaderCollector'
import ItemEtapa1 from '../components/ItemEtapa1'
import ItemEtapa2 from '../components/ItemEtapa2'
import ItemEtapa3 from '../components/ItemEtapa3'
import jwt_decode from 'jwt-decode'
import Modal from 'react-bootstrap/Modal'

class RegisterItem extends Component {
	
    constructor(){
        super()
        this.state = {
			user_id: 0,
			loading: true,
			typeCollection: "",
			resultMsg: null,
			fimSubmit: false,
			etapa: 1,
			pais: '0',
			paises: [],
			padraoM: '0',
			padroesM: [],
			emissor: '',
			emissores: [],
			periodo: '',
			periodos: [],
			formato: '',
			formatos: [],
			material: '',
			materiais: [],
			calendario: '',
			calendarios: [],
			periodoEmissao: '',
			valor: '',
			valorFace: '',
			altura: '',
			largura: '',
			espessura: '',
			peso: '',
			foraCirculacao: false,
			comentario: '',
			gravadorAnverso: '',
			legendaAnverso: '',
			descricaoAnverso: '',
			gravadorVerso: '',
			legendaVerso: '',
			descricaoVerso: '',
			imgsAnverso: [],
			imgsVerso: [],
			showAlertImage: false,
            msgAlertImage: ""
        }
		
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
		this.onChangeCheckBox = this.onChangeCheckBox.bind(this)
		this.nextEtapa = this.nextEtapa.bind(this)
		this.previousEtapa = this.previousEtapa.bind(this)
		this.loadImage = this.loadImage.bind(this)
        this.wrapImage = this.wrapImage.bind(this)
		this.removeImage = this.removeImage.bind(this)
    }
    
	/**Função executada antes da página ser renderizada*/
    componentDidMount(){
		//armazena o nome da coleção passada por parâmetro pela página anterior
		const aux = this.props.location.state !== undefined ? this.props.location.state.name : ""

		//decodifica o token para armazenar o id do usuário
		const token = localStorage.usertoken
		const user_id = jwt_decode(token).id
		this.setState({user_id})

        this.setState({typeCollection: aux})
		
		//envia uma requisição ao backend solicitando uma listagem com os países visíveis (status = 1)
		api.get('paises', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(pais => ({
				id_pais: `${pais.id}`,
				nome_pais: `${pais.nome}`
			}));
		})
		.then( paises => {
			this.setState({
				paises: paises,
				loading: false
			});
			if (paises.length > 0) this.setState({pais: paises[0].id_pais})
		})
		.catch(err => {
			//console.log("Erro ao carregar os paises")
			this.setState({
				loading: false
			});
		})
		
		//envia uma requisição ao backend solicitando uma listagem com os emissores visíveis (status = 1)
		api.get('emissores', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(emissor => ({
				id_emissor: `${emissor.id}`,
				nome_emissor: `${emissor.nome}`
			}));
		})
		.then( emissores => {
			this.setState({
				emissores: emissores,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os emissores")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os períodos visíveis (status = 1)
		api.get('periodos', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(periodo => ({
				id_periodo: `${periodo.id}`,
				nome_periodo: `${periodo.nome}`
			}));
		})
		.then( periodos => {
			this.setState({
				periodos: periodos,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os periodos")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os formatos visíveis (status = 1)
		api.get('formatos', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(formato => ({
				id_formato: `${formato.id}`,
				nome_formato: `${formato.nome}`
			}));
		})
		.then( formatos => {
			this.setState({
				formatos: formatos,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os formatos")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os padrões monetário visíveis (status = 1)
		api.get('padroesMonetario', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(padraoM => ({
				id_padraoM: `${padraoM.id}`,
				nome_padraoM: `${padraoM.nome}`
			}));
		})
		.then( padroesM => {
			this.setState({
				padroesM: padroesM,
				loading: false
			});
			if (padroesM.length > 0) this.setState({padraoM: padroesM[0].id_padraoM})
		})
		.catch(err => {
			//console.log("Erro ao carregar os padrões monetário")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os calendários visíveis (status = 1)
		api.get('calendarios', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(calendario => ({
				id_calendario: `${calendario.id}`,
				nome_calendario: `${calendario.nome}`
			}));
		})
		.then( calendarios => {
			this.setState({
				calendarios: calendarios,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os calendários")
			this.setState({
				loading: false
			});
		}) 

		//envia uma requisição ao backend solicitando uma listagem com os materiais visíveis (status = 1)
		api.get('materiais', {
			headers:{
				status: true
			}
		}).then( response => {
			return response.data.map(material => ({
				id_material: `${material.id}`,
				nome_material: `${material.nome}`
			}));
		})
		.then( materiais => {
			this.setState({
				materiais: materiais,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar os materiais")
			this.setState({
				loading: false
			});
		}) 
	}
		
	/**Armazena um valor em um determinado elemento do state sempre que solicitado*/
    onChange(e){
		const { name, value } = e.target

		//verifica se a quantidade de caracteres do valor ultrapassa o limite do campo "valorFace"
		if (name==="valorFace" && value.length >= 61)
			return
		
		//verifica se a quantidade de caracteres do valor ultrapassa o limite do campo "comentario"
		if (name==="comentario" && value.length >= 201)
			return
		
		//verifica se a quantidade de caracteres do valor ultrapassa o limite dos campos "descricaoVerso" ou "descricaoAnverso"
		if ((name==="descricaoVerso" || name==="descricaoAnverso") && value.length >= 101)
			return

		//verifica se a quantidade de caracteres do valor ultrapassa o limite dos campos "legendaAnverso" ou "legendaVerso"
		if ((name==="legendaAnverso" || name==="legendaVerso") && value.length >= 61)
			return
		
		//verifica se a quantidade de caracteres do valor ultrapassa o limite dos campos "gravadorAnverso" ou "gravadorVerso"
		if ((name==="gravadorAnverso" || name==="gravadorVerso") && value.length >= 51)
			return
		
		this.setState(
			{[name]: value}
		)
	}

	/**Armazena um valor no state informando se o item está ou não fora de circulação (sempre que o checkbox for selecionado)*/
	onChangeCheckBox(e){
		this.setState({
			foraCirculacao: !this.state.foraCirculacao
		});
	}

	/**Função executada após o usuário tentar cadastrar o item, submetendo todas as informações solicitadas*/
    async onSubmit(e){
		e.preventDefault()
		
		this.setState({loading:true})

		const item = {
			colecao: this.props.match.params.id,
			usuario: this.state.user_id,
			pais: this.state.pais,
			emissor: this.state.emissor === '' ? null : this.state.emissor,
			periodo: this.state.periodo === '' ? null : this.state.periodo,
			formato: this.state.formato === '' ? null : this.state.formato,
			padrao_monetario: this.state.padraoM,
			calendario: this.state.calendario === '' ? null : this.state.calendario,
			material: this.state.material === '' ? null : this.state.material,
			periodo_emissao: this.state.periodoEmissao === '' ? null : this.state.periodoEmissao,
			valor: this.state.valor === '' ? null : this.state.valor,
			valorFace: this.state.valorFace === '' ? null : this.state.valorFace,
			fora_circulacao: this.state.foraCirculacao,
			peso: this.state.peso === '' ? null : this.state.peso,
			largura: this.state.largura === '' ? null : this.state.largura,
			altura: this.state.altura === '' ? null : this.state.altura,
			espessura: this.state.espessura === '' ? null : this.state.espessura,
			comentario: this.state.comentario === '' ? null : this.state.comentario
		}

		//cadastra o Item
		try{
			const resultItem = await api.post('item', item)
			//console.log("resultitem", resultItem)
			if (resultItem.data.id){
				
				//cadastra a Face (Anverso)
				try {
					const faceAnverso = {
						item: resultItem.data.id,
						posicao: 1,
						gravador: this.state.gravadorAnverso === '' ? null : this.state.gravadorAnverso,
						legenda: this.state.legendaAnverso === '' ? null : this.state.legendaAnverso,
						descricao: this.state.descricaoAnverso === '' ? null : this.state.descricaoAnverso
					}

					const resultFace1 = await api.post('face', faceAnverso)
					//console.log("resultFace1", resultFace1)
					
					if (!resultFace1.data.error){

						if (this.state.imgsAnverso.length > 0){	

							//cadastra as imagens da face do anverso
							try {
								for (let index = 0; index < this.state.imgsAnverso.length; index++) {
									const element = this.state.imgsAnverso[index];
									
									let formData = new FormData();
									formData.append('item', resultFace1.data.item);
									formData.append('posicao', resultFace1.data.posicao);
									formData.append('imagem', element.img);
									formData.append('img-face', element.file, "img-face.png");
									
									let resImg = await api.post("imagemFace", formData)
									//console.log("resImgAnv", resImg)
									if (!resImg.data.error){
										//console.log("Imagem salva com sucesso")
									}else{
										this.setState({resultMsg: {
											text: "Não foi possível cadastrar a imagem. Por favor, entre em contato com o administrador do sistema.", 
											variant: "danger" 
										}});
										//console.log("Não foi possível cadastrar a imagem da face do anverso.")
									}
								}
							} catch (err3) {
								this.setState({resultMsg: {
									text: "Não foi possível cadastrar a imagem. Por favor, entre em contato com o administrador do sistema.", 
									variant: "danger" 
								}});
							}	
						}
						
					}else{
						this.setState({resultMsg: {
							text: "Não foi possível cadastrar as faces corretamente. Por favor, entre em contato com o administrador do sistema.", 
							variant: "danger"
						}});
						//console.log("Não foi possível cadastrar a face do anverso")
					}
				} catch (err2) {
					//console.log("erro face1:", err2)
					this.setState({resultMsg: {
						text: "Não foi possível cadastrar as faces corretamente. Por favor, entre em contato com o administrador do sistema.", 
						variant: "danger"
					}});
				}

				//cadastra a Face (Verso)
				try {
					
					const faceVerso = {
						item: resultItem.data.id,
						posicao: 2,
						gravador: this.state.gravadorVerso === '' ? null : this.state.gravadorVerso,
						legenda: this.state.legendaVerso === '' ? null : this.state.legendaVerso,
						descricao: this.state.descricaoVerso === '' ? null : this.state.descricaoVerso
					}

					const resultFace2 = await api.post('face', faceVerso)
					//console.log("resultFace2", resultFace2)

					if (!resultFace2.data.error){

						//cadastra as imagens da face do verso
						try {
							if (this.state.imgsVerso.length > 0){

								for (let index = 0; index < this.state.imgsVerso.length; index++) {
									const element = this.state.imgsVerso[index];
									
									let formData = new FormData();
									formData.append('item', resultFace2.data.item);
									formData.append('posicao', resultFace2.data.posicao);
									formData.append('imagem', element.img);
									formData.append('img-face', element.file, "img-face.png");
		
									let resImg = await api.post("imagemFace", formData)
									//console.log("resImgV", resImg)

									if (!resImg.data.error){
										//console.log("imagem salva com sucesso")
									}else{
										this.setState({resultMsg: {
											text: "Não foi possível cadastrar a imagem. Por favor, entre em contato com o administrador do sistema.", 
											variant: "danger" 
										}});
										//console.log("Não foi possível cadastrar a imagem da face do verso. Por favor, entre em contato com o administrador do sistema.")
									}
								}
							}
						} catch (err5) {
							this.setState({resultMsg: {
								text: "Não foi possível cadastrar a imagem. Por favor, entre em contato com o administrador do sistema.", 
								variant: "danger" 
							}});
						}

					}else{
						this.setState({resultMsg: {
							text: "Não foi possível cadastrar as faces corretamente. Por favor, entre em contato com o administrador do sistema.", 
							variant: "danger"
						}});
						//console.log("Não foi possível cadastrar a face do verso")
					}
				} catch (err4) {
					//console.log("erro face2:", err4)
					this.setState({resultMsg: {
						text: "Não foi possível cadastrar as faces corretamente. Por favor, entre em contato com o administrador do sistema.", 
						variant: "danger"
					}});
				}

			}else{
				this.setState({resultMsg: {
					text: "Não foi possível cadastrar o item. Por favor, entre em contato com o administrador do sistema.", 
					variant: "danger"
				}});
				//console.log("Não foi possível cadastrar o item")
			}
		}catch(err){
			//console.log("erro item:", err)
			this.setState({resultMsg: {
				text: "Não foi possível cadastrar o item. Por favor, entre em contato com o administrador do sistema.", 
				variant: "danger"
			}});
		}finally{
			//console.log("finalizouuuu", this.state.resultMsg)
			//define que o processo do submit finalizou e que já pode retornar para a área da coleção
			this.setState({fimSubmit: true}) 
		}
		
    }

	/**Remove uma determinada imagem da lista de imagens  */
	removeImage(e, index){
		e.preventDefault()
		if(this.state.etapa === 2){ //etapa do cadastro da face do anverso
			let aux = this.state.imgsAnverso;
			aux.splice(index, 1);
			this.setState({
				imgsAnverso: aux
			})
		}else if(this.state.etapa === 3){ //etapa do cadastro da face do verso
			let aux = this.state.imgsVerso;
			aux.splice(index, 1);
			this.setState({
				imgsVerso: aux
			})
		}
	}

	/**Vai para a próxima etapa */
	nextEtapa(e){
		e.preventDefault()
		this.setState(prevState => {
			return {etapa: prevState.etapa + 1}
		})
	}

	/**Retorna para a etapa anterior */
	previousEtapa(e){
		e.preventDefault()
		this.setState(prevState => {
			return {etapa: prevState.etapa - 1}
		})
	}

	/**Função que retorna o alert responsável pela mensagem (erro ou sucesso) referente à imagem, eventualmente exibida ao usuário */
	alertImage(){
        return(
            <Modal backdrop="static" keyboard={false} show={this.state.showAlertImage} 
            onHide={() => this.setState({showAlertImage: false})} className="modal-danger-img">
                <Modal.Header closeButton>
                    <div className="icon-box">
                        <i className="fas fa-times"></i>
                    </div>				
                    <h4 className="modal-title w-100">ERRO!</h4>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">{this.state.msgAlertImage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger btn-block" onClick={() => this.setState({showAlertImage: false})}>OK</button>
                </Modal.Footer>
            </Modal>
        )
    }

	/**Abre uma janela para o usuário escolher uma imagem */
	wrapImage(e) {
        document.getElementById("item-fileupload").click();
	}

	/**Armazena a imagem selecionada em uma listagem de imagens */
	loadImage(e, etapa) {
		
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];
		
		//verifica se nenhuma imagem foi selecionada
		if(e.target.files.length === 0){
			return;
		}

		//verifica se o tipo de arquivo selecionado é de fato uma imagem
		if (!/^(?:image\/bmp|image\/cis-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x-cmu-raster|image\/x-cmx|image\/x-icon|image\/x-portable-anymap|image\/x-portable-bitmap|image\/x-portable-graymap|image\/x-portable-pixmap|image\/x-rgb|image\/x-xbitmap|image\/x-xpixmap|image\/x-xwindowdump)$/i.test(file.type)){	
			//console.log("Erro - O tipo da imagem não é válido.");
			this.setState({showAlertImage: true, msgAlertImage: `O tipo do arquivo que você escolheu não é suportado. Escolha uma imagem.`})
			return;
		}

        const limitSizeImg = 3 //limite máximo da imagem em MB
        const sizeImg = ((file.size/1024)/1024).toFixed(4) //armazena o tamanho da imagem selecionada convertendo em MB

		//verifica se a imagem selecionada é maior ou igual ao limite pré-estabelecido
        if (sizeImg >= limitSizeImg){
            //console.log("Erro: Imagem muito grande, a imagem deve ter um tamanho menor que 3MB")
            this.setState({showAlertImage: true, msgAlertImage: `A imagem que você escolheu tem um tamanho maior ou igual a ${limitSizeImg}MB. Diminua o tamanho da imagem ou escolha outra.`})
            return;
        }

		//carrega a imagem
        reader.onloadend = () => {
			if (etapa === "2"){ //área face do anverso
				//adiciona a imagem selecionada na listagem de imagens da face do anverso
				const aux = this.state.imgsAnverso
				aux.push({
					file: file,
					img: reader.result,
				})
				this.setState({imgsAnverso: aux});
			}else if(etapa === "3"){ //área face do verso
				//adiciona a imagem selecionada na listagem de imagens da face do verso
				const aux = this.state.imgsVerso
				aux.push({
					file: file,
					img: reader.result,
				})
				this.setState({imgsVerso: aux});
			}
			
		}
		
		reader.readAsDataURL(file)
	}

	/**Função responsável por retornar o html que será apresentado na área de cadastro de item*/	
    render() {
		//verifica se o processo de cadastro do item (item, faces e imagens) já foi finalizado
		if(this.state.fimSubmit){
			if (!this.state.resultMsg){ //se não houver armazenado alguma mensagem durante o processo de cadastro
				//console.log("e1");
				//redireciona para a área de coleção passando por parâmetro uma mensagem de sucesso
				return <Redirect to={{ pathname: '/colecao/'+this.props.match.params.id, 
					state: { from: this.props.location, name: this.state.typeCollection, 
						msg: {text: "Item cadastrado com sucesso!", variant: "success"}}}} />
			}else{
				//console.log("e2");
				//redireciona para a área de coleção passando por parâmetro a mensagem armazenada durante o processo de cadastro
				return <Redirect to={{ pathname: '/colecao/'+this.props.match.params.id, 
					state: { from: this.props.location, name: this.state.typeCollection, 
						msg: {text: this.state.resultMsg.text, variant: this.state.resultMsg.variant}}}} />
			}
		}
		
		if (this.state.loading){
            return <Spinner />
		}

		//inclui o compomente correspondente à etapa atual
		const retorno = 
		<form className="form-add-item">
			<ul id="progressbar">
				<li className={this.state.etapa >= 1 ? "active": ""}>Etapa 01</li>
				<li className={this.state.etapa >= 2 ? "active": ""}>Etapa 02</li>
				<li className={this.state.etapa >= 3 ? "active": ""}>Etapa 03</li>
			</ul>
			{this.state.etapa === 1 ? <ItemEtapa1 this={this}/> : 
			this.state.etapa === 2 ? <ItemEtapa2 this={this}/>  :
			this.state.etapa === 3 ? <ItemEtapa3 this={this}/>  : null}
	  	</form>

		return (
			<React.Fragment>
				<div className="background-bg background-collector"></div>
				<HeaderCollector history={this.props.history}/>
				{retorno}
				{this.alertImage()}
			</React.Fragment>
		) 
    }
}

export default RegisterItem