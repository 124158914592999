import React, { Component } from 'react'
import api from '../services/api'
import {BsCircleFill} from 'react-icons/bs'
import {FaPencilAlt, FaTrashAlt, FaTrashRestore} from 'react-icons/fa'
import Spinner from './SpinnerAdmin'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'

class TipoColecaoAdmin extends Component {
    
    constructor(){
        super()
        this.state = {
            loading: true,
            tiposColecao: [],
            nameNewTypeCollection: "",
            imgNewTypeCollection: "",
            fileNewTypeCollection: "",
            typeCollectionSelectedName: "",
            typeCollectionSelectedId: "",
            typeCollectionSelectedFile: "",
            typeCollectionSelectedImg: "",
            showAlert: false,
            alertMsg: "",
            alertVariant: "",
            showAlertImage: false,
            msgAlertImage: ""
        }

        this.onChange = this.onChange.bind(this)
        this.newTypeCollection = this.newTypeCollection.bind(this)
        this.loadingTypeCollection = this.loadingTypeCollection.bind(this)
        this.editTypeCollection = this.editTypeCollection.bind(this)
        this.removeTypeCollection = this.removeTypeCollection.bind(this)
        this.restoreTypeCollection = this.restoreTypeCollection.bind(this)
        this.alertVisible = this.alertVisible.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
        this.loadImage = this.loadImage.bind(this)
        this.wrapImage = this.wrapImage.bind(this)
    }

    /**Função executada antes da página ser renderizada*/
    componentDidMount(){
        //console.log("tipoColecao administrador")
        this.loadingTypeCollection() //carrega os tipos de coleção
    }

    /**Função responsável por carregar todos os tipos de coleção cadastrados no banco de dados*/
    loadingTypeCollection(){
        //envia uma requisição ao backend solicitando uma listagem com todos tipos de coleção cadastrados
        api.get('tiposColecao')
        .then( result => {
            //console.log(result.data)
            this.setState({
                tiposColecao: result.data
            })
		})
		.catch(err => {
            this.setState({alertMsg: "Erro ao carregar os tipos de coleção.", alertVariant:"danger"})
		})
        .finally(f => {
            this.setState({loading: false})
        })  
    }

    /**Armazena um valor em um determinado elemento do state sempre que solicitado*/
    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    /**Função que retorna o modal onde o administrador poderá editar o tipo de coleção selecionado*/
    modalEdit(){
        return (
            <div className="modal fade" id="modalEdit" tabIndex="-1" role="dialog" aria-labelledby="ModalEdit" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Editar Tipos de Coleção</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="typeCollection-name" className="col-form-label">Nome do tipo de coleção:</label>
                            <input type="text" name="typeCollectionSelectedName" value={this.state.typeCollectionSelectedName} 
                            className="form-control" id="recipient-name" onChange={this.onChange}/>

                            <label htmlFor="typeCollection-name" className="col-form-label">Imagem do tipo de coleção:</label>
                            <input id="admin-typecollection-edit-fileupload" type="file" accept="image/*" className="form-control-file" onChange={this.loadImage} ref="file" name="edit"/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" onClick={this.editTypeCollection} className="btn btn-primary" data-dismiss="modal">Editar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal responsável por solicitar a confirmação da remoção do tipo de coleção selecionado*/
    modalRemove(){
        return (
            <div className="modal fade" id="modalRemove" tabIndex="-1" role="dialog" aria-labelledby="ModalRemove" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Remover Tipos de Coleção</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 htmlFor="typeCollection-name" className="col-form-label">Você realmente deseja remover esse tipo de coleção?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Não</button>
                            <button type="button" onClick={this.removeTypeCollection} className="btn btn-primary" data-dismiss="modal">Sim</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal responsável por solicitar a confirmação da restauração do tipo de coleção selecionado*/
    modalRestore(){
        return (
            <div className="modal fade" id="modalRestore" tabIndex="-1" role="dialog" aria-labelledby="ModalRestore" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Restaurar Tipo de Coleção</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 htmlFor="typeCollection-name" className="col-form-label">Você deseja restaurar esse tipo de coleção?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Não</button>
                            <button type="button" onClick={this.restoreTypeCollection} className="btn btn-primary" data-dismiss="modal">Sim</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função que retorna o modal onde o administrador poderá cadastrar um novo tipo de coleção*/
    modalAdd(){
        return (
            <div className="modal fade" id="modalAdd" tabIndex="-1" role="dialog" aria-labelledby="ModalAdd" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Cadastrar Novo Tipo de Coleção</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="typeCollection-name" className="col-form-label">Nome do tipo de coleção:</label>
                            <input type="text" name="nameNewTypeCollection" value={this.state.nameNewTypeCollection} 
                            className="form-control" id="recipient-name" onChange={this.onChange}/>

                            <label htmlFor="typeCollection-name" className="col-form-label">Imagem do tipo de coleção:</label>
                            <input id="admin-typecollection-create-fileupload" type="file" accept="image/*" className="form-control-file" onChange={this.loadImage} ref="file" name="create"/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" onClick={this.newTypeCollection} className="btn btn-primary" data-dismiss="modal">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**Função responsável por cadastrar um novo tipo de coleção*/
    newTypeCollection(e){
        e.preventDefault()
        //console.log("salvou novo tipoColecao")
        this.setState({loading: true})
        
        let formData = new FormData();
        formData.append('nome', this.state.nameNewTypeCollection);
        formData.append('imagem', this.state.imgNewTypeCollection);
        
        if(this.state.fileNewTypeCollection !== "") //verifica se alguma imagem foi selecionada
            formData.append('img', this.state.fileNewTypeCollection, "img.png");

        //envia uma requisição ao backend solicitando o cadastro de um novo tipo de coleção
        api.post("tipoColecao", formData)
        .then(result =>{
            if (!result.data.error){
                //console.log("Salvo com sucesso")
                this.loadingTypeCollection() //recarrega os tipos de coleção
                this.setState({alertMsg: "O tipo de coleção foi cadastrado com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao cadastrar o novo tipo de coleção.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao cadastrar o novo tipo de coleção.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false, nameNewTypeCollection: "", imgNewTypeCollection: "", fileNewTypeCollection: ""})
            this.alertVisible()
        })
    }

    /**Função responsável por atualizar dados de determinado tipo de coleção*/
    editTypeCollection(e){
        e.preventDefault()
        //console.log("editar tipoColecao", this.state)
        this.setState({loading: true})

        let formData = new FormData();
        formData.append('nome', this.state.typeCollectionSelectedName);
        formData.append('imagem', this.state.typeCollectionSelectedImg);
        
        if(this.state.typeCollectionSelectedFile !== "") //verifica se alguma imagem foi selecionada
            formData.append('img', this.state.typeCollectionSelectedFile, "img.png");

        //envia uma requisição ao backend solicitando a atualização do tipo de coleção
        api.put(`tipoColecao/${this.state.typeCollectionSelectedId}`, formData)
        .then(result =>{
            if (!result.data.error){
                //console.log("Editado com sucesso")
                this.loadingTypeCollection() //recarrega os tipos de coleção
                this.setState({alertMsg: "As alterações foram salvas com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao salvar as alterações.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao salvar as alterações.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false, typeCollectionSelectedFile: ""})
            this.alertVisible()
        })
    }

    /**Função responsável por remover (logicamente) um determinado tipo de coleção*/
    removeTypeCollection(e){
        e.preventDefault()
        //console.log("remover tipoColecao")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando a remoção (lógica) do tipo de coleção
        api.delete(`tipoColecao/${this.state.typeCollectionSelectedId}`)
        .then(result =>{
            if (!result.data.error){
                //console.log("Removido com sucesso")
                this.loadingTypeCollection() //recarrega os tipos de coleção
                this.setState({alertMsg: "O tipo de coleção foi removido com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao remover o tipo de coleção.", alertVariant:"danger"})
            }
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao remover o tipo de coleção.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false})
            this.alertVisible()
        })
    }

    /**Função responsável por restaurar um determinado tipo de coleção*/
    restoreTypeCollection(e){
        e.preventDefault()
        //console.log("restaurar tipoColecao")
        this.setState({loading: true})

        //envia uma requisição ao backend solicitando a restauração do tipo de coleção
        api.put(`tipoColecao/restaurar/${this.state.typeCollectionSelectedId}`)
        .then(result =>{
            if (!result.data.error){
                //console.log("Restaurado com sucesso", result)
                this.loadingTypeCollection() //recarrega os tipos de coleção
                this.setState({alertMsg: "O tipo de coleção foi restaurado com sucesso.", alertVariant:"success"})
            }else{
                this.setState({alertMsg: "Ocorreu um erro ao restaurar o tipo de coleção.", alertVariant:"danger"})
            }
            
        })
        .catch(e => {
            //console.log("Erro")
            this.setState({alertMsg: "Ocorreu um erro ao restaurar o tipo de coleção.", alertVariant:"danger"})
        })
        .finally(f => {
            this.setState({loading: false})
            this.alertVisible()
        })
    }

    /**Exibe uma mensagem ao usuário (erro ou sucesso)*/
    alertVisible(){
        this.setState({showAlert:true})
        
        //determina por quanto tempo a mensagem será exibida
        setTimeout(() => {
            if (this.state.showAlert)
                this.closeAlert()
        }, 5000);
    } 

    /**Fecha a mensagem exibida ao usuário*/
    closeAlert(){
        this.setState({showAlert:false})    
    }

    /**Função que retorna o alert responsável pela mensagem (erro ou sucesso) eventualmente exibida ao usuário */
    alert(){
        return(
            <Alert show={this.state.showAlert} variant={this.state.alertVariant} 
            onClose={this.closeAlert} dismissible>

                <div className="icon">
                    {this.state.alertVariant === "success" ? <i className="fa fa-check"></i> :
                    (this.state.alertVariant === "danger" ? <i className="fa fa-times-circle"></i> : null)}
                </div>

                {this.state.alertVariant === "success" ? <strong>Sucesso! </strong> : 
                (this.state.alertVariant === "danger" ? <strong>Erro! </strong> : "")}
                
                {this.state.alertMsg}
            </Alert>
        )
    }

    /**Função que retorna o alert responsável pela mensagem (erro ou sucesso) referente à imagem, eventualmente exibida ao administrador*/
    alertImage(){
        return(
            <Modal backdrop="static" keyboard={false} show={this.state.showAlertImage} 
            onHide={() => this.setState({showAlertImage: false})} className="modal-danger-img">
                <Modal.Header closeButton>
                    <div className="icon-box">
                        <i className="fas fa-times"></i>
                    </div>				
                    <h4 className="modal-title w-100">ERRO!</h4>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">{this.state.msgAlertImage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger btn-block" onClick={() => this.setState({showAlertImage: false})}>OK</button>
                </Modal.Footer>
            </Modal>
        )
    }

    /**Abre uma janela para o usuário escolher uma imagem*/
    wrapImage(e) {
        if (e.target.name==="edit") //se o administrador estiver no modal de edição
		    document.getElementById("admin-typecollection-edit-fileupload").click();
        else if(e.target.name==="create") //se o administrador estiver no modal de cadastro
            document.getElementById("admin-typecollection-create-fileupload").click();
	}

    /**Armazena a imagem selecionada em uma listagem de imagens */
	loadImage(e) {
		
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];
		
        //verifica se nenhuma imagem foi selecionada
		if(e.target.files.length === 0){
			return;
		}

        //verifica se o tipo de arquivo selecionado é de fato uma imagem
		if (!/^(?:image\/bmp|image\/cis-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x-cmu-raster|image\/x-cmx|image\/x-icon|image\/x-portable-anymap|image\/x-portable-bitmap|image\/x-portable-graymap|image\/x-portable-pixmap|image\/x-rgb|image\/x-xbitmap|image\/x-xpixmap|image\/x-xwindowdump)$/i.test(file.type)){	
			this.setState({showAlertImage: true, msgAlertImage: `O tipo do arquivo que você escolheu não é suportado. Escolha uma imagem.`})
			return;
		}

        const limitSizeImg = 3 //limite máximo da imagem em MB
        const sizeImg = ((file.size/1024)/1024).toFixed(4) //armazena o tamanho da imagem selecionada convertendo em MB

        //verifica se a imagem selecionada é maior ou igual ao limite pré-estabelecido
        if (sizeImg >= limitSizeImg){
            //console.log("Erro: Imagem muito grande, a imagem deve ter um tamanho menor que 3MB")
            this.setState({showAlertImage: true, msgAlertImage: `A imagem que você escolheu tem um tamanho maior ou igual a ${limitSizeImg}MB. Diminua o tamanho da imagem ou escolha outra.`})
            return;
        }

        //console.log("e.target.name: ", e.target.name)

        if (e.target.name === "edit"){ //verifica se a imagem foi selecionada a partir do modal de edição
            reader.onloadend = () => {
                this.setState({
                    typeCollectionSelectedFile: file,
                    typeCollectionSelectedImg: reader.result,
                });
            }

        }else if (e.target.name === "create"){ //verifica se a imagem foi selecionada a partir do modal de cadastro
            reader.onloadend = () => {
                this.setState({
                    fileNewTypeCollection: file,
                    imgNewTypeCollection: reader.result,
                });
            }
        }
		
		reader.readAsDataURL(file)
	}

    /**Função responsável por retornar o html que será apresentado quando a opção tipo de coleção for selecionada*/
    render() {
        if (this.state.loading){
            return <Spinner/>
		}

        let rows = []

        //cria as linhas da tabela preenchendo as colunas com as informações dos tipos de coleção
        this.state.tiposColecao.forEach(c => {

            //armazena a data em que o tipo de coleção foi atualizado pela última vez, no formato DD/MM/AAAA HH:MM
            let data = new Date(c.atualizadoEm)
            const dia = (data.getDate() < 10) ? '0' + data.getDate().toString() : data.getDate().toString();
            const mes = ((data.getMonth()+1) < 10) ? '0' + (data.getMonth()+1).toString() : (data.getMonth()+1).toString();
            const ano = data.getFullYear()
            const hora = (data.getHours() < 10) ? '0' + data.getHours().toString() : data.getHours().toString();
            const minutos = (data.getMinutes() < 10) ? '0' + data.getMinutes().toString() : data.getMinutes().toString();
            const dataFormat = dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos

            //insere cada linha em um array chamado "rows"
            rows.push(
                <tr key={c.id}>
                    <td>{c.id}</td>
                    <td><img key={c.id} src={process.env.REACT_APP_BACKEND_URL + c.imagem} alt="file" className="typeCollection" /></td>
                    <td>{c.nome}</td>
                    <td className="comp-admin-status">
                        {c.status === 1 ? 
                            <React.Fragment>
                                <BsCircleFill size="7px" color="green" className="mr-2"/> Ativo
                            </React.Fragment> : 
                            <React.Fragment>
                                <BsCircleFill size="7px" color="red" className="mr-2"/> Excluído
                            </React.Fragment>
                        }
                        </td>
                    <td>{dataFormat}</td>
                    <td>
                        {c.status === 1 ? 
                            <React.Fragment>
                                <button title="Editar" className="admin-actions" data-toggle="modal" 
                                data-target="#modalEdit" data-backdrop="static" data-keyboard="false"
                                onClick={() => this.setState({typeCollectionSelectedName: c.nome, typeCollectionSelectedId: c.id, typeCollectionSelectedImg: c.imagem})}>
                                    <FaPencilAlt/>
                                </button>
                                <button title="Remover" className="admin-actions" data-toggle="modal" 
                                data-target="#modalRemove" data-backdrop="static" data-keyboard="false"
                                onClick={() => this.setState({typeCollectionSelectedName: c.nome, typeCollectionSelectedId: c.id})}>
                                    <FaTrashAlt/>
                                </button>
                            </React.Fragment> :
                            <button title="Restaurar" className="admin-actions" data-toggle="modal" 
                            data-target="#modalRestore" data-backdrop="static" data-keyboard="false"
                            onClick={() => this.setState({typeCollectionSelectedName: c.nome, typeCollectionSelectedId: c.id})}>
                                <FaTrashRestore/>
                            </button>
                        }
                    </td>
                </tr>
            )
        });
        
        //cria a tabela e inclui as linhas geradas e armazenadas no array chamado "rows"
        const retorno =
        <div className="component-admin">
            <div className="container-fluid p-0">
                <div className="mb-4">
                    <h5 className="header-title"> Tipos de Coleção</h5>
                    <hr/>
                </div>

                <div className="">
                    <button className="comp-admin-add mb-3" data-toggle="modal" data-target="#modalAdd"
                    data-backdrop="static" data-keyboard="false">
                        <i className="fas fa-plus"></i> Adicionar
                    </button>
                    <div className="pull-right"> </div>
                </div>
                <div>
                    <div className="table-responsive">
                        <table className="table table-hover table-borderless">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Imagem</th>
                                    <th>Nome</th>
                                    <th>Status</th>
                                    <th>Data de Modificação</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
        
                </div>
            </div>
            
        </div>

        return (
            <React.Fragment>
                {this.alert()}
                {retorno}
                {this.modalAdd()}
                {this.modalEdit()}
                {this.modalRemove()}
                {this.modalRestore()}
                {this.alertImage()}
            </React.Fragment>
            
        ) 
    }
}

export default TipoColecaoAdmin