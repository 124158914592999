import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./Auth";

/** Rotas privadas que serão acessadas apenas por determinados tipos de usuário:
 * primeiro é verificado se o usuário está autenticado e se o acesso é permitido
 * para o seu tipo de usuário. 
 * Em caso afirmativo, o componente (página) é inicializado.
 * Caso o contrário, o token é apagado (apenas se o usuário não estiver mais autenticado) 
 * e o sistema redireciona para a página inicial
*/

export const PrivateRoute = ({ component: Component, ...rest}) =>(
    
    <Route {...rest} render= {props =>(
        <React.Fragment>
            {isAuthenticated().status && (rest.type === undefined || 
            rest.type.split(',').indexOf(isAuthenticated().tipo_usuario_id) > -1) 
            ? (
                <Component {...props} />
            ) 
            : (
                <React.Fragment>
                    {!isAuthenticated().status ? localStorage.removeItem('usertoken') : null}
                    <Redirect to={{ pathname: '/', state: { from: props.location}}} />
                </React.Fragment>
            )}
        </React.Fragment>
    )}/>
    
)