import React, { Component } from 'react'
import '../styles/EditUser.css'
import api from '../services/api'
import Spinner from '../components/Spinner'
import jwt_decode from 'jwt-decode'
import HeaderCollector from '../components/HeaderCollector'
import Alert from 'react-bootstrap/Alert'
import InputMask from "react-input-mask"

class EditUser extends Component {
	
    constructor(){
        super()
        this.state = {
			loading: true,
			userId: this.getUserId(),
			email:'',
			senha:'',
			nome:'',
			telefone:'',
			nascimento:'',
			municipio:'',
			municipios: [],
			estado:'',
			estados: [],
			sexo: '',
			bairro: '',
			cep: '',
			numero: '',
			logradouro: '',
			complemento: '',
			newPassword: '',
			confirmNewPassword: '',
			validation: {
				nome: {wasValidated: false},
				telefone: {wasValidated: false},
				nascimento: {wasValidated: false},
				estado: {wasValidated: false},
				municipio: {wasValidated: false},
				sexo: {wasValidated: false},
				bairro: {wasValidated: false},
				cep: {wasValidated: false},
				numero: {wasValidated: false},
				logradouro: {wasValidated: false},
				newPassword: {wasValidated: false},
				confirmNewPassword: {wasValidated: false},
			},
			showAlert: false,
            alertMsg: "",
            alertVariant: "",
        }
		
		this.getUserId = this.getUserId.bind(this)
		this.changeEstado = this.changeEstado.bind(this)
		this.setMunicipios = this.setMunicipios.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
		this.modalEditPassword = this.modalEditPassword.bind(this)
		this.updatePassword = this.updatePassword.bind(this)
		this.resetEditPassword = this.resetEditPassword.bind(this)
		this.alertVisible = this.alertVisible.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
    }
    
	/**Função executada antes da página ser renderizada*/
    componentDidMount(){
		//envia uma requisição ao backend com o objetivo de receber uma listagem com os estados
		api.get('estados').then( response => {
			return response.data.map(estado => ({
				id_estado: `${estado.id}`,
				nome_estado: `${estado.nome}`
			}));
		})
		.then( estados => {
			//console.log(estados)
			this.setState({
				estados: estados,
				loading: false
			});
		})
		.catch(err => {
			//console.log("Erro ao carregar estados")
		}) 
		.finally(f => {
			this.setState({
				loading: false
			});
		})	
		
		//envia uma requisição ao backend solicitando os dados do usuário
		api.get('user', {
			headers:{
				id: this.state.userId
			}
		}).then( res => {
			//console.log("res",res)

			if (!res.data.error){
				//armazena a data de nascimento no formato AAAA-MM-DD
				let data = new Date((res.data.user.nascimento).replace(/-/g, '/').replace(/T.+/,''))
				const dia = (data.getDate() < 10) ? '0' + data.getDate().toString() : data.getDate().toString();
				const mes = ((data.getMonth()+1) < 10) ? '0' + (data.getMonth()+1).toString() : (data.getMonth()+1).toString();
				const ano = data.getFullYear()
				const dataNasc = ano + "-" + mes + "-" + dia
				
				this.setState({
					email: res.data.user.email,
					nome: res.data.user.nome,
					telefone: res.data.user.telefone,
					nascimento: dataNasc,
					sexo:  res.data.user.sexo,
					bairro:  res.data.user.bairro,
					cep:  res.data.user.cep,
					numero:  res.data.user.numero,
					logradouro:  res.data.user.logradouro,
					complemento:  res.data.user.complemento === null ? "" : res.data.user.complemento,
					estado: res.data.user.estado_id,
					municipio: res.data.user.cidade_id
				});
				this.setMunicipios(res.data.user.estado_id); //carrega os munícipios do estado
				this.validate() //chama a função para validar todos os campos
				this.setState({loading: false});
			}
			else{
				this.setState({alertMsg: "Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.", alertVariant:"danger"})
				this.setState({loading: false});
				this.alertVisible()
			}	
		})
		.catch(err => {
			//console.log("Erro ao carregar dados do usuário")
			this.setState({alertMsg: "Ocorreu um erro ao carregar dados do usuário. Por favor, entre em contato com o administrador do sistema.", alertVariant:"danger"})
			this.setState({loading: false});
			this.alertVisible()
		}) 
	}

	/**Função responsável por decodificar o token e retornar o id do usuário */
	getUserId(){
        const token = localStorage.usertoken
		const decoded = jwt_decode(token)
		//console.log(decoded)
        return decoded.id
    }
	
	/**Função responsável pela validação de um campo */
	validateField(name, value) {

		let validation = { ...this.state.validation };

        switch (name) {

            case "nome": //valida o campo "nome"
				if(!value || value.length < 5) {
					validation.nome = {
						hasError: true,
						errorCode:'NOM001',
						errorMessage:"O campo 'nome' deve possuir pelo menos 5 (cinco) caracteres."
					}
				} else {
					validation.nome = {
						hasError: false
					}
				}
				validation.nome.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, nome: validation.nome }}))
				break;
			case "sexo": //valida o campo "sexo"
				if(!value || !(value === "M" || value === "F")) {
					validation.sexo = {
						hasError: true,
						errorCode:'NOM001',
						errorMessage:"O campo 'sexo' deve ser selecionado."
					}
				} else {
					validation.sexo = {
						hasError: false
					}
				}
				validation.sexo.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, sexo: validation.sexo }}))
				break;
			case "telefone": //valida o campo "telefone"
				//const phoneRegExp = RegExp(/^\(\d{2}\)\s\d{4,5}-\d{4}$/)
				const phoneRegExp = RegExp(/^\(\d{2}\)\s\d{5}-\d{4}$/)

				if(!phoneRegExp.test(value)) {
					validation.telefone = {
						hasError: true,
						errorCode:'EMA001',
						errorMessage:"O 'telefone' deve possuir o formato '(99) 99999-9999'"
					}
				} else {
					validation.telefone = {
						hasError: false
					}
				}
				validation.telefone.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, telefone: validation.telefone }}))
				break;
            case "newPassword": //valida o campo "nova senha"
				const passwordRegExp = RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)

				if(!passwordRegExp.test(value)) {
					validation.newPassword = {
						hasError: true,
						errorCode:'EMA001',
						errorMessage:"A senha deve possuir 8 caracteres, sendo, no mínimo, uma letra maiúscula, uma letra minúscula e um número."
					}
				} else {
					validation.newPassword = {
						hasError: false
					}
				}
				validation.newPassword.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, newPassword: validation.newPassword }}))
				break;
			case "confirmNewPassword": //valida o campo "confirmar senha"
				const confirmPasswordRegExp = RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)
				
				if(!confirmPasswordRegExp.test(value) || value !== this.state.newPassword) {
					validation.confirmNewPassword = {
						hasError: true,
						errorCode:'EMA001',
						errorMessage:"Repita a senha corretamente."
					}
				} else {
					validation.confirmNewPassword = {
						hasError: false
					}
				}
				validation.confirmNewPassword.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, confirmNewPassword: validation.confirmNewPassword }}))
				break;
			case "estado": //valida o campo "estado"
				if(value === "" || !value) {
					validation.estado = {
						hasError: true,
						errorCode:'EST001',
						errorMessage:"O campo 'estado' deve ser selecionado."
					}
				} else {
					validation.estado = {
						hasError: false
					}
				}
				validation.estado.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, estado: validation.estado }}))
				break;
			case "municipio": //valida o campo "município"
				if(value === "" || !value) {
					validation.municipio = {
						hasError: true,
						errorCode:'MUN001',
						errorMessage:"O campo 'município' deve ser selecionado."
					}
				} else {
					validation.municipio = {
						hasError: false
					}
				}
				validation.municipio.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, municipio: validation.municipio }}))
				break;
			case "nascimento": //valida o campo "nascimento"
				const birthdayRegExp = RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)

				if(!birthdayRegExp.test(value)) {
					validation.nascimento = {
						hasError: true,
						errorCode:'NAS001',
						errorMessage:"O campo 'nascimento' deve possuir um formato válido."
					}
				} else {
					validation.nascimento = {
						hasError: false
					}
				}
				validation.nascimento.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, nascimento: validation.nascimento }}))
                break;
			case "bairro": //valida o campo "bairro"
				if(!value || value.length < 2) {
					validation.bairro = {
						hasError: true,
						errorCode:'NOM001',
						errorMessage:"O campo 'bairro' deve possuir pelo menos 2 (dois) caracteres."
					}
				} else {
					validation.bairro = {
						hasError: false
					}
				}
				validation.bairro.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, bairro: validation.bairro }}))
				break;
			case "logradouro": //valida o campo "logradouro"
				if(!value || value.length < 2) {
					validation.logradouro = {
						hasError: true,
						errorCode:'NOM001',
						errorMessage:"O campo 'logradouro' deve possuir pelo menos 2 (dois) caracteres."
					}
				} else {
					validation.logradouro = {
						hasError: false
					}
				}
				validation.logradouro.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, logradouro: validation.logradouro }}))
				break;
			case "numero": //valida o campo "número"
				const numRegExp = RegExp(/^[0-9]+$/)
				if(!numRegExp.test(value)) {
					validation.numero = {
						hasError: true,
						errorCode:'EST001',
						errorMessage:"O campo 'número' deve ser um valor natural."
					}
				} else {
					validation.numero = {
						hasError: false
					}
				}
				validation.numero.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, numero: validation.numero }}))
				break;
			case "cep": //valida o campo "cep"
				const cepRegExp = RegExp(/^[0-9]{2}.[0-9]{3}-[0-9]{3}$/)

				if(!cepRegExp.test(value)) {
					validation.cep = {
						hasError: true,
						errorCode:'EMA001',
						errorMessage:"O 'cep' deve possuir o formato '00.000-000'"
					}
				} else {
					validation.cep = {
						hasError: false
					}
				}
				validation.cep.wasValidated = true
				this.setState(prevState => ({ validation: { ...prevState.validation, cep: validation.cep }}))
				break;
            default:
                break;
        }
	}
	
	/**Verifica se todos os campos (com exceção dos campos "nova senha" e "confirmar senha") estão válidos*/
	validate() {

		let validation = { ...this.state.validation };

		let wasValidated = true
		for( var key in validation ) {
			if(key !== "newPassword" && key !== "confirmNewPassword" && !validation[key].wasValidated) {
				this.validateField(key, this.state[key])
				wasValidated = false;
			}
		}

		if(!wasValidated) return false;

		validation = { ...this.state.validation };

		return 	!( 	validation.nome.hasError || 
					validation.telefone.hasError ||
					validation.sexo.hasError ||
					validation.estado.hasError ||
					validation.municipio.hasError ||
					validation.nascimento.hasError || 
					validation.cep.hasError ||
					validation.bairro.hasError ||
					validation.logradouro.hasError ||
					validation.numero.hasError)

	}

	/**Verifica se os campos "nova senha" e "confirmar senha" estão válidos*/
	validatePassword(){
		let validation = { ...this.state.validation };

		let wasValidated = true

		for( var key in validation ) {
			if(!validation[key].wasValidated && (key === "newPassword" || key === "confirmNewPassword")) {
				this.validateField(key, this.state[key])
				wasValidated = false;
			}
		}

		if(!wasValidated) return false;

		validation = { ...this.state.validation };

		return 	!( 	validation.newPassword.hasError || 
					validation.confirmNewPassword.hasError)
	}
	
	/**Armazena um valor em um determinado elemento do state e executa a função 
	 *que verifica se esse valor é válido para o elemento em questão sempre que solicitado
	*/
    onChange(e){
		const { name, value } = e.target
		
		this.setState(
			{[name]: value}, 
			() => { this.validateField(name, value) }
		)
	}
	
	/**Armazena o estado selecionado no state e chama a função que carrega os municípios desse estado*/
	changeEstado(e){
		this.onChange(e)
		
		this.setState(prevState => ({
			...prevState,
			municipio: '',
			validation: {
				...prevState.validation,
				municipio: {
					wasValidated: false,
					hasError: null
				}
			}
		}))
		this.setMunicipios(e.target.value)
	}
	
	/**Função responsável por carregar os municípios de um determinado estado*/
	setMunicipios(estado){
		//console.log(estado)
		if (estado !== ""){
			api.get('cidades', {
				headers: {
					estado: estado
				}
			}).then( response => {
				return response.data.map(municipio => ({
					id_municipio: `${municipio.id}`,
					nome_municipio: `${municipio.nome}`
				}));
			})
			.then( municipios => {
				this.setState({
					municipios
				});
			})
			.catch(err => {
				//console.log("Erro ao carregar municípios")
			})

		}else{
			this.setState({
				municipios:[]
			});
		}
		
	}

	/**Função que retorna o modal onde o usuário poderá alterar sua senha*/
	modalEditPassword(){
		const { validation } = this.state;
        return (
            <div className="modal fade" id="modalEditPassword" tabIndex="-1" role="dialog" aria-labelledby="ModalEditPassword" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Alterar Senha</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetEditPassword}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="password-new" className="col-form-label">Nova senha:</label>

                            <input type="password" className={!validation.newPassword.wasValidated ? "form-control": validation.newPassword.hasError ? "is-invalid form-control" : "is-valid form-control"}
							name="newPassword" value={this.state.newPassword} onChange={this.onChange}/>
							{validation.newPassword.hasError ? (<span className="invalid-feedback">{validation.newPassword.errorMessage}</span>) : null}

							<label htmlFor="password-confirm-new" className="col-form-label">Confirmar senha:</label>

                            <input type="password" className={!validation.confirmNewPassword.wasValidated ? "form-control": validation.confirmNewPassword.hasError ? "is-invalid form-control" : "is-valid form-control"} 
							name="confirmNewPassword" value={this.state.confirmNewPassword} onChange={this.onChange}/>
							{validation.confirmNewPassword.hasError ? (<span className="invalid-feedback">{validation.confirmNewPassword.errorMessage}</span>) : null}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.resetEditPassword}>Cancelar</button>
                            <button type="button" disabled={this.state.newPassword==='' || this.state.confirmNewPassword==='' || 
							validation.newPassword.hasError || validation.confirmNewPassword.hasError} 
							onClick={this.updatePassword} className="btn btn-primary" data-dismiss="modal">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

	/**Função responsável por armazenar a nova senha inserida pelo usuário */
	updatePassword(e){
		e.preventDefault()

        if (this.validatePassword()) {
			this.setState({senha: this.state.newPassword})
		}
		this.resetEditPassword(e)
	}

	/**Função responsável por resetar as campos de "nova senha" e "confirmar senha"*/
	resetEditPassword(e){
		e.preventDefault()
		this.setState({newPassword:'', confirmNewPassword:''})

		let validation = { ...this.state.validation };
		validation.newPassword = {wasValidated: false}
		validation.confirmNewPassword = {wasValidated: false}

		this.setState(prevState => ({ validation: { ...prevState.validation, 
		newPassword: validation.newPassword, confirmNewPassword: validation.confirmNewPassword }}))
	}

	/**Função executada após o usuário selecionar a opção "salvar"*/
    onSubmit(e){
		e.preventDefault()
		this.setState({loading:true})
		
		//console.log('subimitteeee')
		
        if (this.validate()) { //verifica se todos os campos estão válidos
			//console.log('validou')
			const user = {
				senha: this.state.senha,
				nome: this.state.nome,
				telefone: this.state.telefone, 
				sexo: this.state.sexo,
				nascimento: this.state.nascimento,
				cidade: this.state.municipio,
				bairro: this.state.bairro,
				numero: this.state.numero,
				logradouro: this.state.logradouro,
				complemento: this.state.complemento === '' ? null : this.state.complemento,
				cep: this.state.cep,
			}
			
			//envia uma requisição ao backend solicitando a atualização dos dados do usuário
			api.put(`user/${this.state.userId}`, user)
			.then( res => {
				if(!res.data.error) {
					//console.log("editado com sucesso")
					this.setState({alertMsg: "Dados atualizados.", alertVariant:"success"})
				} else {
					this.setState({alertMsg: "Não foi possível atualizar os seus dados. Por favor, entre em contato com o administrador do sistema.", alertVariant:"danger"})
				}
			})
			.catch(error => {
				this.setState({loading:false});
				this.setState({alertMsg: "Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.", alertVariant:"danger"})
			})
			.finally( () => {
				this.setState({loading:false, senha: ''});
				this.alertVisible()
				this.resetEditPassword(e)
			})
		}else
			this.setState({loading:false})
    }

	/**Exibe uma mensagem ao usuário (erro ou sucesso)*/
	alertVisible(){
        this.setState({showAlert:true})
        
		//determina por quanto tempo a mensagem será exibida
        setTimeout(() => {
            if (this.state.showAlert)
                this.closeAlert()
        }, 5000);
    } 

	/**Fecha a mensagem exibida ao usuário */
	closeAlert(){
        this.setState({showAlert:false})    
    }

	/**Função que retorna o alert responsável pela mensagem (erro ou sucesso) eventualmente exibida ao usuário */
	alert(){
        return(
            <Alert show={this.state.showAlert} variant={this.state.alertVariant} 
            onClose={this.closeAlert} dismissible>

                <div className="icon">
                    {this.state.alertVariant === "success" ? <i className="fa fa-check"></i> :
                    (this.state.alertVariant === "danger" ? <i className="fa fa-times-circle"></i> : null)}
                </div>

                {this.state.alertVariant === "success" ? <strong>Sucesso! </strong> : 
                (this.state.alertVariant === "danger" ? <strong>Erro! </strong> : "")}
                
                {this.state.alertMsg}
            </Alert>
        )
    }
	
	/**Função responsável por retornar o html que será apresentado à página de perfil de usuário*/
    render() {
		if (this.state.loading){
            return <Spinner />
		}

		const { validation } = this.state;

		const retorno = 
		<div className="container-edit-user">
			<div className="body-edit-user">
				<section className="section-head text-center">
					<strong>Perfil</strong>
				</section>
				<section className="section-body text-left">
					{this.alert()}
					<form onSubmit={this.onSubmit} encType="multipart/form-data" noValidate>
						<div className="register-form">

							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label className="lb-title">Email</label>
										<input type="email" disabled className="form-control"
										placeholder="Email *" name="email" value={this.state.email} onChange={this.onChange}/>
									</div>
									<div className="form-group mb-4">
										<label className="lb-title">Senha</label>
										<div className="form-goup">
											<button type="button" className="btn btn-info btn-sm" data-toggle="modal" data-target="#modalEditPassword" 
											data-backdrop="static" data-keyboard="false">Alterar Senha</button>
											{this.state.senha !== '' ? <span className="info-new-password">Senha modificada.</span>: null}
										</div>
									</div>
									<div className="form-group">
										<label className="lb-title">Nome</label>
										<input type="text" className={!validation.nome.wasValidated ? "form-control": validation.nome.hasError ? "is-invalid form-control" : "form-control"} 
										placeholder="Nome *"name="nome" value={this.state.nome} onChange={this.onChange}/>
										{validation.nome.hasError ? (<span className="invalid-feedback">{validation.nome.errorMessage}</span>) : null}
									</div>
									<div className="form-group">
										<label className="lb-title">Telefone</label>
										<InputMask className={!validation.telefone.wasValidated ? "form-control": validation.telefone.hasError ? "is-invalid form-control" : "form-control"} 
										mask="(99) 99999-9999" placeholder="Telefone *" name="telefone" value={this.state.telefone} onChange={this.onChange}/>
										{validation.telefone.hasError ? (<span className="invalid-feedback">{validation.telefone.errorMessage}</span>) : null}
									</div>
									<div className="form-group">
										<label className="lb-title">Nascimento</label>
										<input type="date" className={!validation.nascimento.wasValidated ? "form-control": validation.nascimento.hasError ? "is-invalid form-control" : "form-control"} 
										placeholder="Nascimento *" name="nascimento" value={this.state.nascimento} onChange={this.onChange}/>
										{validation.nascimento.hasError ? (<span className="invalid-feedback">{validation.nascimento.errorMessage}</span>) : null}
									</div>
									<div className="form-group">
										<label className="lb-title">Sexo</label>
										<div className={!validation.sexo.wasValidated ? "maxl": validation.sexo.hasError ? "is-invalid maxl" : "maxl"}>
											<label className="radio inline mr-3"> 
												<input type="radio" name="sexo" value="M" onChange={this.onChange} 
												checked={this.state.sexo === "M"}/>
												<span> Masculino </span> 
											</label>
											<label className="radio inline"> 
												<input type="radio" name="sexo" value="F" onChange={this.onChange} 
												checked={this.state.sexo === "F"}/>
												<span> Feminino </span> 
											</label>
										</div>
										{validation.sexo.hasError ? (<span className="invalid-feedback">{validation.sexo.errorMessage}</span>) : null}
									</div>
								</div>
								<div className="col-md-6">
									
									<div className="row">
										<div className="col-md-6 padding-r">
											<div className="form-group">
												<label className="lb-title">Estado</label>
												<select className={!validation.estado.wasValidated ? "form-control": validation.estado.hasError ? "is-invalid form-control" : "form-control"}  
												name="estado" placeholder="Estado" value={this.state.estado} aria-label="Estado" onChange={this.changeEstado}>
													<option className="hidden" value="" disabled>estado</option>
													{
														this.state.estados.map( function(estado) {
															return <option key={estado.id_estado} value={estado.id_estado}>{estado.nome_estado}</option>;
														})
													}
												</select>
												{validation.estado.hasError ? (<span className="invalid-feedback">{validation.estado.errorMessage}</span>) : null}
											</div>
										</div>
										<div className="col-md-6 padding-l">
											<div className="form-group">
												<label className="lb-title">Município</label>
												<select className={!validation.municipio.wasValidated ? "form-control": validation.municipio.hasError ? "is-invalid form-control" : "form-control"}  
												name="municipio" placeholder="Municipio" value={this.state.municipio} aria-label="Município" onChange={this.onChange}>
													<option className="hidden" value="" disabled>município</option>
													{
														this.state.municipios.map( function(municipio) {
															return <option key={municipio.id_municipio} value={municipio.id_municipio}>{municipio.nome_municipio}</option>;
														})
													}
												</select>
												{validation.municipio.hasError ? (<span className="invalid-feedback">{validation.municipio.errorMessage}</span>) : null}
											</div>
										</div>
									</div>
									
									<div className="form-group">
										<label className="lb-title">Bairro</label>
										<input type="text" className={!validation.bairro.wasValidated ? "form-control": validation.bairro.hasError ? "is-invalid form-control" : "form-control"} 
										placeholder="Bairro *" name="bairro" value={this.state.bairro} onChange={this.onChange}/>
										{validation.bairro.hasError ? (<span className="invalid-feedback">{validation.bairro.errorMessage}</span>) : null}
									</div>

									<div className="row">
										<div className="col-md-6 padding-r">
											<div className="form-group">
												<label className="lb-title">CEP</label>
												<InputMask className={!validation.cep.wasValidated ? "form-control": validation.cep.hasError ? "is-invalid form-control" : "form-control"} 
												mask="99.999-999" placeholder="CEP *" name="cep" value={this.state.cep} onChange={this.onChange}/>
												{validation.cep.hasError ? (<span className="invalid-feedback">{validation.cep.errorMessage}</span>) : null}
											</div>
										</div>
										<div className="col-md-6 padding-l">
											<div className="form-group">
												<label className="lb-title">Número</label>
												<input type="text" className={!validation.numero.wasValidated ? "form-control": validation.numero.hasError ? "is-invalid form-control" : "form-control"}  
												placeholder="Número *" name="numero" value={this.state.numero} onChange={this.onChange}/>
												{validation.numero.hasError ? (<span className="invalid-feedback">{validation.numero.errorMessage}</span>) : null}
											</div>
										</div>	
									</div>
									
									
									<div className="form-group">
										<label className="lb-title">Logradouro</label>
										<input type="text" className={!validation.logradouro.wasValidated ? "form-control": validation.logradouro.hasError ? "is-invalid form-control" : "form-control"}  
										placeholder="Logradouro *" name="logradouro" value={this.state.logradouro} onChange={this.onChange}/>
										{validation.logradouro.hasError ? (<span className="invalid-feedback">{validation.logradouro.errorMessage}</span>) : null}
									</div>
									
									<div className="form-group">
										<label className="lb-title">Complemento</label>
										<input type="text" className="form-control" placeholder="Complemento"
										name="complemento" value={this.state.complemento} onChange={this.onChange}/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12 text-center mt-3 row-action-buttons">
									<input type="button" className="btn btn-secondary action-button" value="Voltar" 
									onClick={e => this.props.history.goBack()}/>
									<input type="submit" className="btn btn-success action-button" value="Salvar"/>
								</div>
							</div>
						</div>
					</form>
				</section>	
			</div>
		</div>

		return (
			<React.Fragment>
				<div className="background-bg background-collector"></div>
				<HeaderCollector history={this.props.history}/>
				{retorno}
				{this.modalEditPassword()}
			</React.Fragment>
			
		) 
    }
}

export default EditUser