import React, { Component } from 'react'
import '../styles/ViewItem.css'
import api from '../services/api'
import Spinner from '../components/Spinner'
import HeaderCollector from '../components/HeaderCollector'

class ViewItem extends Component {
	
    constructor(){
        super()
        this.state = {
			loading: true,
			pais: '',
			padraoM: '',
			emissor: '',
			periodo: '',
			formato: '',
			material: '',
			calendario: '',
			periodoEmissao: '',
			valor: '',
			valorFace: '',
			altura: '',
			largura: '',
			espessura: '',
			peso: '',
			foraCirculacao: false,
			comentario: '',
			gravadorAnverso: '',
			legendaAnverso: '',
			descricaoAnverso: '',
			gravadorVerso: '',
			legendaVerso: '',
			descricaoVerso: '',
			imgsAnverso: [],
			imgsVerso: [],
        }		
    }
    
	/**Função executada antes da página ser renderizada*/
    componentDidMount(){
		//armazena o nome da coleção passada por parâmetro pela página anterior
		const aux = this.props.location.state !== undefined ? this.props.location.state.name : ""

        this.setState({typeCollection: aux})

		//envia uma requisição ao backend solicitando os dados do item
		api.get(`item/${this.props.match.params.idI}`)
		.then( result => {
			if (!result.data.error){
				let dataPeriodoEmissao = ""
				if (result.data[0].periodoEmissao !== null){ //se houver período de emissão
					//armazena o período de emissão no formato AAAA-MM-DD
					let data = new Date((result.data[0].periodoEmissao).replace(/-/g, '/').replace(/T.+/,''))
					const dia = (data.getDate() < 10) ? '0' + data.getDate().toString() : data.getDate().toString();
					const mes = ((data.getMonth()+1) < 10) ? '0' + (data.getMonth()+1).toString() : (data.getMonth()+1).toString();
					const ano = data.getFullYear()
					dataPeriodoEmissao = dia + '/' + mes + '/' + ano
				}
				
				this.setState({
					itemId: result.data[0].id,
					pais: result.data[0].pais_nome,
					emissor: result.data[0].emissor_nome === null ? "-" : result.data[0].emissor_nome,
					periodo: result.data[0].periodo_nome === null ? "-" : result.data[0].periodo_nome,
					formato: result.data[0].formato_nome === null ? "-" : result.data[0].formato_nome,
					padraoM: result.data[0].padrao_monetario_nome,
					calendario: result.data[0].calendario_nome === null ? "-" : result.data[0].calendario_nome,
					material: result.data[0].material_nome === null ? "-" : result.data[0].material_nome,
					periodoEmissao: dataPeriodoEmissao === "" ? "-" : dataPeriodoEmissao,
					valor: result.data[0].valor === null ? "-" : result.data[0].valor,
					valorFace: result.data[0].valorFace === null ? "-" : result.data[0].valorFace,
					foraCirculacao: !!result.data[0].foraCirculacao,
					peso: result.data[0].peso === null ? "-" : result.data[0].peso,
					largura: result.data[0].largura === null ? "-" : result.data[0].largura,
					altura: result.data[0].altura === null ? "-" : result.data[0].altura,
					espessura: result.data[0].espessura === null ? "-" : result.data[0].espessura,
					comentario: result.data[0].comentario === null ? "-" : result.data[0].comentario
				})
			}else{
				//console.log("Erro ao carregar dados do item")
			}
			//console.log(result)
		})
		.catch(err => {
			//console.log("Erro ao carregar dados do item")
		})
		.finally(() => {
			this.setState({loading: false});
		})

		//envia uma requisição ao backend solicitando os dados da face do anverso do item
		api.get(`face/${this.props.match.params.idI}/1`)
		.then( face1 => {
			//console.log("face1", face1)
			this.setState({loading:true});

			if(!face1.data.error) {
				this.setState({
					gravadorAnverso: face1.data[0].gravador === null ? "-" : face1.data[0].gravador,
					legendaAnverso: face1.data[0].legenda === null ? "-" : face1.data[0].legenda,
					descricaoAnverso: face1.data[0].descricao === null ? "-" : face1.data[0].descricao
				})

				//envia uma requisição ao backend solicitando uma listagem com as imagens da face do anverso
				api.get("imagensFace", {
					headers: {
						item: face1.data[0].item_id,
						posicao: face1.data[0].posicao
					}
				}).then(resImg =>{
					//console.log(resImg)
					if (!resImg.data.error){
						const aux = []
						resImg.data.forEach(i => {
							aux.push({
								img: process.env.REACT_APP_BACKEND_URL + i.imagem,
								pos: 1
							})
						});
						this.setState({imgsAnverso: aux})
					}else{
						//console.log("Não foi possível carregar a imagem da face do anverso. Por favor, entre em contato com o administrador do sistema.")
					}
				})
				.catch(e => {
					//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
				})
				.finally( () => {
					this.setState({loading:false});
				})
			} else {
				this.setState({loading:false});
				//console.log("Não foi possível carregar a face do anverso. Por favor, entre em contato com o administrador do sistema.")
			}
		})
		.catch(error => {
			this.setState({loading:false});
			//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
		})

		//envia uma requisição ao backend solicitando os dados da face do verso do item
		api.get(`face/${this.props.match.params.idI}/2`)
		.then( face2 => {
			//console.log("face2", face2)
			this.setState({loading:true});

			if(!face2.data.error) {
				this.setState({
					gravadorVerso: face2.data[0].gravador === null ? "-" : face2.data[0].gravador,
					legendaVerso: face2.data[0].legenda === null ? "-" : face2.data[0].legenda,
					descricaoVerso: face2.data[0].descricao === null ? "-" : face2.data[0].descricao
				})

				//envia uma requisição ao backend solicitando uma listagem com as imagens da face do verso
				api.get("imagensFace", {
					headers: {
						item: face2.data[0].item_id,
						posicao: face2.data[0].posicao
					}
				}).then(resImg =>{
					//console.log(resImg)
					if (!resImg.data.error){
						const aux = []
						resImg.data.forEach(i => {
							aux.push({
								img: process.env.REACT_APP_BACKEND_URL + i.imagem,
								pos: 2
							})
						});
						this.setState({imgsVerso: aux})
					}else{
						//console.log("Não foi possível carregar a imagem da face do verso. Por favor, entre em contato com o administrador do sistema.")
					}
				})
				.catch(e => {
					//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
				})
				.finally( () => {
					this.setState({loading:false});
				})
			} else {
				this.setState({loading:false});
				//console.log("Não foi possível carregar a face do verso. Por favor, entre em contato com o administrador do sistema.")
			}
		})
		.catch(error => {
			this.setState({loading:false});
			//console.log("Ocorreu um erro inesperado. Por favor, entre em contato com o administrador do sistema.")
		})
	}

	/**Função responsável por retornar um html com as imagens das faces (anverso e verso) do item*/
	loadImages(){
		let indicators = []
		let itens = []

		//concatena as imagens do anverso com as imagens do verso e armazena em uma lista
		let images = this.state.imgsAnverso.concat(this.state.imgsVerso) 

		images.forEach((i, index) => {
			indicators.push(
				<li key={"carousel-indicators-"+index} data-target="#main-carousel" data-slide-to={index+""} className={index === 0 ? "active": ""}></li>
			)

			itens.push(
				<div key={"carousel-item-"+index} className={index === 0 ? "carousel-item active": "carousel-item"}>
					<img className="d-block img-fluid" src={i.img} alt=""/>
					<div className="carousel-caption d-none d-md-block">
					</div>
				</div>
			)
		});

		//console.log(images)

		//retorna um carrossel com as imagens incluídas
		return (
			<React.Fragment>
				<ol className="carousel-indicators">
					{indicators}
				</ol>
				
				<div className="carousel-inner">
					{itens}
				</div>
			</React.Fragment>
		)
	}
	
	/**Função responsável por retornar o html que será apresentado na área de visualização do item*/
    render() {
		//console.log("state: ", this.state)
		if (this.state.loading){
            return <Spinner />
		}

		//inclui o carrossel com as imagens do item apenas se houver alguma imagem do item cadastrada
		const retorno = 
		<div className="container-view-item">
			<div className="body-view-item">
				<section className="section-head text-center">
					<strong>Item</strong>
				</section>
				<section className="section-body text-left">
					<div className="row">
						<div className="col-md-6">
							<div className="row">
								<div className= {this.state.imgsAnverso.length + this.state.imgsVerso.length > 0
								? "col-md-12" : "col-md-12 d-none"}>
									<div className="carousel slide mb-3" id="main-carousel" data-ride="carousel">
										
										{this.loadImages()}
										
										<a href="#main-carousel" className="carousel-control-prev" data-slide="prev">
											<span className="carousel-control-prev-icon"></span>
											<span className="sr-only" aria-hidden="true">Prev</span>
										</a>
										<a href="#main-carousel" className="carousel-control-next" data-slide="next">
											<span className="carousel-control-next-icon"></span>
											<span className="sr-only" aria-hidden="true">Next</span>
										</a>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div id="accordionFace1" className="mb-3">
										<div className="card">
											<div className="card-header" id="headingOne">
												<button className="btn" data-toggle="collapse" data-target="#collapseFace1" aria-expanded="true" aria-controls="collapseFace1">
													Informações do anverso
													<i className="fas fa-chevron-down"></i>
												</button>
											</div>

											<div id="collapseFace1" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionFace1">
												<div className="card-body">
													<table className="table">
														<tbody>
															<tr>
																<td className="view-title">Gravador</td>
																<td className="view-text">{this.state.gravadorAnverso}</td>
															</tr>
															<tr>
																<td className="view-title">Legenda</td>
																<td className="view-text">{this.state.legendaAnverso}</td>
															</tr>
															<tr className="view-title-comentario">
																<td className="view-title" colSpan="2">Descrição</td>
															</tr>
															<tr className="view-text-comentario">
																<td className="view-text view-comentario" colSpan="2">{this.state.descricaoAnverso}</td>
															</tr>
														</tbody>
													</table>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div id="accordionFace2" className="mb-3">
								<div className="card">
									<div className="card-header" id="headingOne">
										<button className="btn" data-toggle="collapse" data-target="#collapseFace2" aria-expanded="true" aria-controls="collapseFace2">
											Informações do verso
											<i className="fas fa-chevron-down"></i>
										</button>
									</div>

									<div id="collapseFace2" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionFace2">
										<div className="card-body">
											<table className="table">
												<tbody>
													<tr>
														<td className="view-title">Gravador</td>
														<td className="view-text">{this.state.gravadorVerso}</td>
													</tr>
													<tr>
														<td className="view-title">Legenda</td>
														<td className="view-text">{this.state.legendaVerso}</td>
													</tr>
													<tr className="view-title-comentario">
														<td className="view-title" colSpan="2">Descrição</td>
													</tr>
													<tr className="view-text-comentario">
														<td className="view-text view-comentario" colSpan="2">{this.state.descricaoVerso}</td>
													</tr>
												</tbody>
											</table>

										</div>
									</div>
								</div>
							</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div id="accordionItem" className="mb-3">
								<div className="card">
									<div className="card-header" id="headingOne">
										<button className="btn" data-toggle="collapse" data-target="#collapseItem" aria-expanded="true" aria-controls="collapseItem">
											Informações gerais
											<i className="fas fa-chevron-down"></i>
										</button>
									</div>

									<div id="collapseItem" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionItem">
										<div className="card-body">
											<table className="table">
												<tbody>
													<tr>
														<td className="view-title">País</td>
														<td className="view-text">{this.state.pais}</td>
													</tr>
													<tr>
														<td className="view-title">Padrão Monetário</td>
														<td className="view-text">{this.state.padraoM}</td>
													</tr>
													<tr>
														<td className="view-title">Emissor</td>
														<td className="view-text">{this.state.emissor}</td>
													</tr>
													<tr>
														<td className="view-title">Período</td>
														<td className="view-text">{this.state.periodo}</td>
													</tr>
													<tr>
														<td className="view-title">Formato</td>
														<td className="view-text">{this.state.formato}</td>
													</tr>
													<tr>
														<td className="view-title">Calendário</td>
														<td className="view-text">{this.state.calendario}</td>
													</tr>
													<tr>
														<td className="view-title">Material</td>
														<td className="view-text">{this.state.material}</td>
													</tr>
													<tr>
														<td className="view-title">Largura</td>
														<td className="view-text">{this.state.largura} mm</td>
													</tr>
													<tr>
														<td className="view-title">Altura</td>
														<td className="view-text">{this.state.altura} mm</td>
													</tr>
													<tr>
														<td className="view-title">Espessura</td>
														<td className="view-text">{this.state.espessura} mm</td>
													</tr>
													<tr>
														<td className="view-title">Peso</td>
														<td className="view-text">{this.state.peso} g</td>
													</tr>
													<tr>
														<td className="view-title">Período de Emissão</td>
														<td className="view-text">{this.state.periodoEmissao}</td>
													</tr>
													<tr>
														<td className="view-title">Valor</td>
														<td className="view-text">R$ {this.state.valor}</td>
													</tr>
													<tr>
														<td className="view-title">Valor da Face</td>
														<td className="view-text">{this.state.valorFace}</td>
													</tr>
													<tr>
														<td className="view-title">Fora de Circulação</td>
														<td className="view-text">{this.state.foraCirculacao ? "Sim" : "Não"}</td>
													</tr>
													<tr className="view-title-comentario">
														<td className="view-title" colSpan="2">Comentário</td>
													</tr>
													<tr className="view-text-comentario">
														<td className="view-text view-comentario" colSpan="2">{this.state.comentario}</td>
													</tr>
												</tbody>
											</table>
										

										</div>
									</div>
								</div>
							</div>
							
							
						</div>
					</div>
					
					
					<div className="row">
						<div className="col-md-12 text-center mt-3">
							<input type="button" name="previous" className="previous action-button btn btn-primary" value="Voltar" 
							onClick={e => this.props.history.goBack()}/>
						</div>
					</div>
					
				</section>
			
			</div>
		
	  	</div>

		return (
			<React.Fragment>
				<div className="background-bg background-collector"></div>
				<HeaderCollector history={this.props.history}/>
				{retorno}
			</React.Fragment>
		) 
    }
}

export default ViewItem