import React, { Component } from 'react'
import "../styles/HeaderCollector.css";
import {FiMoreVertical} from 'react-icons/fi'

class HeaderCollector extends Component {

    constructor(){
        super()
        this.logout = this.logout.bind(this)
        this.goProfile = this.goProfile.bind(this)
    }
    
    /**Função que realiza o logout (desconecta o colecionador do sistema) */
    logout(e){
        e.preventDefault()
        //console.log(this)
        localStorage.removeItem('usertoken') //remove o token do LocalStorage
        this.props.history.push("/") //redireciona para a tela de login
    }

    /**Função que redireciona o colecionador para a área de perfil do usuário */
    goProfile(e){
        e.preventDefault()
        this.props.history.push("/perfil")
    }

    /**Função responsável por retornar o html do navbar (barra superior)*/
    render(){
        return (
            <header className="header-collector" id="header">
                <h4 className="header_title">Collecionne</h4>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <button className="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FiMoreVertical size="1.7rem"/>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <button className="dropdown-item" onClick={this.goProfile}>Perfil</button>
                            <button className="dropdown-item" onClick={this.logout}>Sair</button>
                        </div>
                    </li>
                </ul>
                
            </header>
        )
    }
}

export default HeaderCollector
